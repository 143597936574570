import React from "react";
import PropTypes from "prop-types";

import Chat from "../../components/Chat";

import { postNewConversationMessage } from "../../utils/api";

export default class ConversationPage extends React.Component {
  static propTypes = {
    conversationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    token: PropTypes.string
  };

  onConversationMessageCreated = message => {
    postNewConversationMessage(this.props.conversationId, message).catch(
      error => {
        console.error("Failed to post new converstation message:", error);
      }
    );
  };

  render() {
    let room = `conversation-${this.props.conversationId}`;

    return (
      <Chat
        token={this.props.token}
        url={`chat/conversations/${room}`}
        room={room}
        show_input={true}
        onMessageCreated={this.onConversationMessageCreated}
      />
    );
  }
}
