import React from "react";
import propTypes from "prop-types";

import TimeAgo from "../TimeAgo";

import { signIn } from "../../utils/user";

import "./styles.css";

const ConversationpropTypes = {
  recipientGamertag: propTypes.string.isRequired,
  conversationDbUrl: propTypes.string.isRequired,
  conversationLink: propTypes.string.isRequired
};

export default class ConversationPreviews extends React.Component {
  static propTypes = {
    token: propTypes.string.isRequired,
    conversations: propTypes.arrayOf(
      propTypes.shape(ConversationpropTypes).isRequired
    ).isRequired
  };

  constructor(props) {
    super(props);

    this.state = { user: null };
  }

  componentDidMount() {
    signIn(this.props.token, false)
      .then(user => {
        this.setState({ user: user });
      })
      .catch(error => {
        console.error("An error occurred during sign in:", error);
      });
  }

  render() {
    if (this.state.user) {
      return this.props.conversations.map(convo => (
        <ConversationPreview key={convo.conversationDbUrl} {...convo} />
      ));
    } else {
      return this.props.conversations.map(convo => (
        <SimpleConversationPreview key={convo.conversationDbUrl} {...convo} />
      ));
    }
  }
}

const SimpleConversationPreview = props => {
  return (
    <div className="conversation-preview">
      <h4 className="gamertag">
        <a href={props.conversationLink}>{props.recipientGamertag}</a>
      </h4>
    </div>
  );
};
SimpleConversationPreview.propTypes = ConversationpropTypes;

class ConversationPreview extends React.Component {
  static propTypes = ConversationpropTypes;

  constructor(props) {
    super(props);

    this.messageQuery = null;

    this.state = {
      lastMessage: null
    };
  }

  onLastMessageAddedChanged = dataSnapshot => {
    let key = dataSnapshot.key;
    let message = dataSnapshot.val();
    message.key = key;
    this.setState({ lastMessage: message });
  };

  onLastMessageRemoved = dataSnapshot => {
    let key = dataSnapshot.key;
    let message = dataSnapshot.val();
    message.key = key;
    this.setState(prevState => {
      if (prevState.lastMessage.key === key) {
        return { lastMessage: null };
      }
    });
  };

  componentDidMount() {
    if (this.messageQuery) {
      this.messageQuery.off();
    }

    this.messageQuery = firebase
      .database()
      .ref(this.props.conversationDbUrl)
      .orderByChild("createdAt")
      .limitToLast(1);

    this.messageQuery.on("child_added", this.onLastMessageAddedChanged);
    this.messageQuery.on("child_changed", this.onLastMessageAddedChanged);
    this.messageQuery.on("child_removed", this.onLastMessageRemoved);
  }

  componentWillUnmount() {
    if (this.messageQuery) {
      this.messageQuery.off();
    }
  }

  render() {
    if (this.state.lastMessage) {
      return (
        <div className="conversation-preview mt-4">
          <h4 className="gamertag">
            <a href={this.props.conversationLink}>
              {this.props.recipientGamertag}
            </a>
          </h4>
          <small>
            <TimeAgo className="time" date={this.state.lastMessage.createdAt} />
          </small>
          <p>{this.state.lastMessage.text}</p>
          <small>
            <a href={this.props.conversationLink}>full conversation &raquo;</a>
          </small>
        </div>
      );
    } else {
      return <SimpleConversationPreview {...this.props} />;
    }
  }
}
