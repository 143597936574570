import React from "react";
import * as api from "../../utils/api";


export default class GroupDescription extends React.Component {

  state = {
    description: "...",
    editing: false,
  }

  componentDidMount() {
    this.fetchGroupDescription()
  }

  fetchGroupDescription = () => {
    api.fetchGroupInfo({ groupId: this.props.groupId, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      this.setState({ description: response.data.formatted_description, editing: false })
    })
  }

  updateGroupDescription = (description) => {
    this.setState({ description: description, editing: false })
    api.updateGroupDescription({ groupId: this.props.groupId, description: description, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      console.log(response)
    })
  };



  render() {
    return (
      <div className="media border border-glass rounded p-3 text-white bg-dark">
        <div className="card-body px-6">
          {this.state.editing && this.props.isModerator ? (
            <div>
              <textarea className="form-control" rows="7" value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}></textarea>
              <div className="gap-xy-1 mt-2">
                < button type="button" className={"btn btn-xs btn-glass btn-success"} onClick={() => this.updateGroupDescription(this.state.description)} >save</button>
                < button type="button" className={"btn btn-xs btn-glass btn-secondary"} onClick={() => this.fetchGroupDescription()} >cancel</button>
              </div>
            </div>
          ) : (
              <p className="mb-4" onClick={() => this.setState({ editing: !this.state.editing })}>{this.state.description}</p>
            )}

        </div>
        {this.props.isModerator ? (
          <div className="badges badges-right">
            <button type="button" className="btn btn-xs btn-glass btn-dark" onClick={() => this.setState({ editing: !this.state.editing })}><i
              className="fa fa-pencil-square-o"></i></button>
          </div>) : null}
      </div>
    )
  }


}