import React from "react";
import PropTypes from "prop-types";
import Chat from "../../components/Chat";

export default class GroupsPage extends React.Component {
  static propTypes = {
    groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    token: PropTypes.string
  };

  render() {
    let room = `group-${this.props.groupId}`;

    return (
      <Chat
        token={this.props.token}
        url={`chat/groups/${room}`}
        room={room}
        show_input={this.props.show_input}
      />
    );
  }
}
