import React from "react";
import * as api from "../../utils/api";


export default class GroupTags extends React.Component {

  state = {
    loading: true,
    allTags: [],
    groupTags: [],
    editing: false
  }

  componentDidMount() {
    this.fetchAllTags()
    this.fetchGroupTags()
  }

  fetchAllTags = () => {
    api.fetchAllGroupTags({ suggestedOnly: false }).then((response) => {
      this.setState({ allTags: response, editing: false })
    })
  }

  fetchGroupTags = () => {
    api.fetchGroupInfo({ groupId: this.props.groupId, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      this.setState({ groupTags: response.data.tags_cache, editing: false, loading: false })
    })
  }

  updateTags = (tag) => {
    if (this.state.groupTags.includes(tag)) {
      this.setState({ groupTags: this.state.groupTags.filter(i => i !== tag) })
    } else {
      this.setState({ groupTags: [...this.state.groupTags, tag] })
    }

    api.updateGroupTags({ groupId: this.props.groupId, tag: tag, headers: ReactOnRails.authenticityHeaders() }).then((response) => {

    })
  };


  render() {

    if (this.state.loading) {
      return (
        <div className="gap-xy-1">...</div>
      )
    }

    return (
      <div>
        {this.props.isModerator && this.state.editing ? (
          <div className="gap-xy-1">
            {this.state.allTags.map(tag => (
              < button type="button" className={"btn btn-xs btn-glass " + (this.state.groupTags.includes(tag) ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.updateTags(tag)} key={tag}>{tag}</button>
            )
            )
            }
            < button type="button" className={"btn btn-xs btn-glass btn-success"} onClick={() => this.setState({ editing: !this.state.editing })} >done</button>

          </div>
        ) : (
            <div className="gap-xy-1">

              {this.state.allTags.filter(tag => (
                this.state.groupTags.includes(tag))).map(tag => (
                  < span className={"badge badge-dark"} key={tag}>{tag}</span>
                )
                )
              }
              {this.props.isModerator ? (
                < button type="button" className={"badge badge-dark"} style={{ borderWidth: 0, cursor: "pointer" }} onClick={() => this.setState({ editing: !this.state.editing })} ><i className="fa fa-plus"></i></button>
              ) : null}

            </div>
          )}
      </div>
    )
  }
}