import PropTypes from "prop-types";
import React from "react";
import ReactOnRails from "react-on-rails";
import ReCAPTCHA from "react-google-recaptcha";

import Loading from "../Loading";
import * as api from "../../utils/api";

export default class SignupForm extends React.Component {
  static propTypes = {
    form_authenticity_token: PropTypes.string,
    discord_signup_url: PropTypes.string,
    step: PropTypes.number,
    currentUserId: PropTypes.number,
    gameId: PropTypes.number,
    activateGroupMind: PropTypes.bool,
    groupSignup: PropTypes.bool,
    captchaKey: PropTypes.string,
  };

  state = {
    platformsList: ["xbox-one", "ps4", "pc", "stadia"],
    platform: "",
    ageRange: "",
    playSchedule: "",
    playScheduleList: [
      "Weekday Mornings and Weekends",
      "Weekday Afternoons and Weekends",
      "Weekday Evenings and Weekends",
      "Weekdays Latenight and Weekends",
    ],
    interestsList: [],
    interests: [],
    firstName: "",
    email: "",
    password: "",
    termsCheckbox: false,
    step: this.props.step || 0,
    errors: "",
    loading: true,
    captchaToken: "",
  };

  componentDidMount() {
    // this.fetchAllTags()
    this.setState({ loading: false });
    if (this.props.currentUserId && this.state.step == 1 && newTimeZone) {
      api.updateUser({
        headers: ReactOnRails.authenticityHeaders(),
        body: {
          user_id: this.props.currentUserId,
          time_zone: newTimeZone,
        },
      });
    }
  }

  fetchAllTags = () => {
    this.setState({ loading: true });

    api.fetchAllGroupTags({ suggestedOnly: true }).then((response) => {
      console.log(response);
      this.setState({ interestsList: response, loading: false });
    });
  };

  onChange = (value) => {
    this.setState({ captchaToken: value });
  };

  updatePlatform = (platform) => {
    this.setState({ platform, step: 2 });
  };

  updatePlaySchedule = (playSchedule) => {
    this.setState({ playSchedule, step: 3 });
  };

  updateAge = (ageRange) => {
    this.fetchAllTags();
    this.setState({ ageRange, step: 4 });
  };

  updateInterests = (interest) => {
    this.state.interests.includes(interest)
      ? this.setState({ interests: this.state.interests.filter((i) => i !== interest) })
      : this.setState({ interests: [...this.state.interests, interest] });
  };

  checkIfLoggedIn = () => {
    if (this.props.currentUserId) {
      this.setState({ loading: true });

      api
        .updateUser({
          headers: ReactOnRails.authenticityHeaders(),
          body: {
            user_id: this.props.currentUserId,
            age: this.state.ageRange,
            hide_age: true,
            platform: this.state.platform,
            play_schedule: this.state.playSchedule,
            play_style: this.state.interests.includes("competitive") ? "serious" : "casual",
            time_zone: newTimeZone,
            tag_list: this.state.interests,
            tos_privacy_agreement: true,
            primary_game_id: this.props.gameId,
            activate_group_mind: this.props.activateGroupMind,
          },
        })
        .then(function (response) {
          if (response.data.redirect_url) {
            let redirect = window.encodeURI(response.data.redirect_url);
            console.log(redirect);
            window.location.href = redirect;
          } else {
            this.setState({ loading: false, termsCheckbox: false, errors: response.data.error });
          }
        })
        .catch((e) => {
          this.setState({
            loading: false,
            termsCheckbox: false,
            errors: "An error occurred, please double check your info and try again.",
          });
          console.log(e);
        });
    } else {
      this.setState({ step: 5 });
    }
  };

  updateGamertag = (gamertag) => {
    this.setState({ gamertag });
  };

  updateEmail = (email) => {
    this.setState({ email });
  };

  updatePassword = (password) => {
    this.setState({ password });
  };

  handleCheckbox = () => {
    this.setState({ termsCheckbox: !this.state.termsCheckbox });
  };

  formReady = () => {
    return this.state.gamertag && this.state.email && this.state.password && this.state.termsCheckbox;
  };

  onSubmit = () => {
    this.setState({ loading: true });

    api
      .createUser({
        headers: ReactOnRails.authenticityHeaders(),
        body: {
          gamertag: this.state.gamertag,
          email: this.state.email,
          password: this.state.password,
          age: this.state.ageRange,
          hide_age: true,
          platform: this.state.platform,
          play_schedule: this.state.playSchedule,
          play_style: this.state.interests.includes("competitive") ? "serious" : "casual",
          time_zone: newTimeZone,
          tag_list: this.state.interests,
          tos_privacy_agreement: true,
          primary_game_id: this.props.gameId,
          activate_group_mind: this.props.activateGroupMind,
          captcha_token: this.state.captchaToken,
          created_from: "website",
        },
      })
      .then(function (response) {
        if (response.data.redirect_url) {
          let redirect = window.encodeURI(response.data.redirect_url);
          console.log(redirect);
          window.location.href = redirect;
        } else {
          this.setState({ loading: false, termsCheckbox: false, errors: response.data.error });
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          termsCheckbox: false,
          errors: "An error occurred, please double check your info and try again.",
        });
        console.log(e);
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          {this.props.groupSignup ? <h3>Creating Account...</h3> : <p className="lead">Joining Group... </p>}
          <h3></h3>
          <br />
          <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
            <div style={{ minHeight: "300px" }}>
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.state.step == 0 && (
          <div>
            {this.props.groupSignup ? (
              <h3>First, Create Your Account:</h3>
            ) : this.props.guildId ? (
              <div>
                <h3>Join {this.props.groupName} To Schedule Games:</h3>
              </div>
            ) : (
              <h3>Create Your Account</h3>
            )}

            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              <a href={this.props.discord_signup_url} className="btn btn-primary btn-lg">
                Sign Up With Discord
              </a>
              {this.props.groupSignup ? (
                <a href={"/groups/new?step=1"} className="btn btn-primary btn-lg">
                  Sign Up With Email
                </a>
              ) : this.props.guildId ? null : (
                <button type="button" className="btn btn-primary btn-lg" onClick={() => this.setState({ step: 1 })}>
                  {" "}
                  Sign Up With Email
                </button>
              )}
            </div>
          </div>
        )}

        {this.state.step == 1 && (
          <div>
            <p className="lead" data-font="Lato:700">
              {"looks like you're in the " + newTimeZone + " timezone."}
            </p>
            {this.props.groupSignup ? (
              <h3>What platform does your group mostly play on?</h3>
            ) : (
              <h3>What platform do you mostly play on?</h3>
            )}
            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              {this.state.platformsList.map((platform) => (
                <button type="button" className={"btn " + platform} onClick={() => this.updatePlatform(platform)}>
                  {platform}
                </button>
              ))}
            </div>
          </div>
        )}

        {this.state.step == 2 && (
          <div>
            {this.props.groupSignup ? <h3>When does your group usually play?</h3> : <h3>When do you usually play?</h3>}
            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              {this.state.playScheduleList.map((playSchedule) => (
                <button
                  type="button"
                  className="btn btn-glass btn-dark"
                  onClick={() => this.updatePlaySchedule(playSchedule)}
                >
                  {playSchedule}
                </button>
              ))}
            </div>
            <br />
            <div className="d-inline">
              <button
                type="button"
                className="btn btn-disabled pull-left"
                onClick={() => this.setState({ step: 1, playSchedule: "" })}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
            </div>
          </div>
        )}

        {this.state.step == 3 && (
          <div>
            {this.props.groupSignup ? (
              <h3>What's your group's rough age range?</h3>
            ) : (
              <h3>What's your rough age range?</h3>
            )}
            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("15")}>
                teens
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("25")}>
                twenties{" "}
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("35")}>
                thirties
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("45")}>
                forties
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("55")}>
                fifties
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("65")}>
                sixties
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("75")}>
                seventies +
              </button>
            </div>
            <br />
            <div className="d-inline">
              <button
                type="button"
                className="btn btn-disabled pull-left"
                onClick={() => this.setState({ step: 2, ageRange: "" })}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
            </div>
          </div>
        )}

        {this.state.step == 4 && (
          <div>
            {this.props.groupSignup ? (
              <h3>Pick a few tags to describe your group (optional...):</h3>
            ) : (
              <h3>What tags describe you? Pick a few:</h3>
            )}
            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              {this.state.interestsList.map((interest) => (
                <button
                  type="button"
                  className={"btn btn-glass " + (this.state.interests.includes(interest) ? "btn-primary" : "btn-dark")}
                  onClick={() => this.updateInterests(interest)}
                >
                  {interest}
                </button>
              ))}
            </div>
            <br />
            <br />
            <div className="col-10 col-md-12 col-xl-10">
              <div className="d-inline">
                <button
                  type="button"
                  className="btn btn-disabled pull-left"
                  onClick={() => this.setState({ step: 3, interests: [] })}
                >
                  <i className="fa fa-chevron-left"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg pull-right"
                  onClick={() => this.checkIfLoggedIn()}
                >
                  next
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.step == 5 && (
          <div>
            {this.state.errors ? (
              <p className="text-danger">{this.state.errors}</p>
            ) : this.props.groupSignup ? (
              <h3>Almost done! Finish signup to join:</h3>
            ) : (
              <h3>We found your perfect group! Finish signup to join:</h3>
            )}
            <br />
            <div className="col-8 col-md-8 col-xl-6">
              <form className="input-transparent">
                <div className="form-group input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="USERNAME"
                    value={this.state.gamertag}
                    onChange={(e) => this.updateGamertag(e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span>
                  </div>
                </div>

                <div className="form-group input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="EMAIL ADDRESS"
                    value={this.state.email}
                    onChange={(e) => this.updateEmail(e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fa fa-envelope"></i>
                    </span>
                  </div>
                </div>

                <div className="form-group input-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="CHOOSE PASSWORD"
                    value={this.state.password}
                    onChange={(e) => this.updatePassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fa fa-lock"></i>
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="custom-control custom-checkbox" onClick={() => this.handleCheckbox()}>
                    <input type="checkbox" className="custom-control-input" />
                    <label className="custom-control-label">
                      <a href="/terms" target="_blank">
                        I agree to the Terms of Service and Privacy Policy
                      </a>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <ReCAPTCHA sitekey={this.props.captchaKey} onChange={this.onChange} theme="dark" />
                </div>

                <div className="d-inline">
                  <button
                    type="button"
                    className="btn btn-disabled pull-left"
                    onClick={() => this.setState({ step: 4, interests: [] })}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </button>
                  {this.formReady() ? (
                    <button type="button" className="btn btn-primary pull-right" onClick={() => this.onSubmit()}>
                      Create Account
                    </button>
                  ) : (
                    <button type="button" className="btn btn-secondary pull-right">
                      Create Account
                    </button>
                  )}
                </div>
              </form>
            </div>
            <br /> <br />
          </div>
        )}
      </div>
    );
  }
}
