import React from "react";
import PropTypes from "prop-types";

import Chat from "../../components/Chat";

export default class SherpaHqPage extends React.Component {
  static propTypes = {
    token: PropTypes.string
  };

  render() {
    return (
      <Chat
        token={this.props.token}
        url="chat/sherpa-hq"
        room="sherpa-hq"
        show_input={true}
      />
    );
  }
}
