import React from "react";
import PropTypes from "prop-types";

import MessageInput from "../MessageInput";

import { ChatMessagePropType } from "../types";

import "./styles.css";

export default class MessageEdit extends React.Component {
  static propTypes = {
    message: PropTypes.shape(ChatMessagePropType).isRequired,
    onSubmit: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.message.text
    };
  }

  onChange = (e, value) => {
    this.setState({ value });
  };

  onSubmit = e => {
    e.preventDefault();

    this.props.onSubmit(this.state.value);
  };

  render() {
    return (
      <form className="form" onSubmit={this.onSubmit}>
        <div className="input-wrapper text-dark">
          <MessageInput
            onChange={this.onChange}
            value={this.state.value}
            users={this.props.users}
          />
        </div>
        <button className="btn btn-primary submit">
          Edit
        </button>
      </form>
    );
  }
}
