import axios from "axios";

export class ApiError extends Error {
  constructor(message, data) {
    super(`${message} - Data:\n${JSON.stringify(data, null, 2)}`);

    this.data = data;
  }
}

function get(url, params) {
  let config = {
    headers: { "X-API-KEY": "9612590ec7fe4c748123f94ce0d1adbf" },
    params
  };

  return axios
    .get("https://www.bungie.net/Platform" + url, config)
    .then(data => {
      if (!data) {
        throw new ApiError("No data returned", data);
      }
      if (data.data.ErrorCode !== 1) {
        throw new ApiError("Error occurred", data);
      }
      if (!data.data.Response) {
        throw new ApiError("Data did not match expected format", data);
      }
      return data.data.Response;
    });
}

export function getDestiny2Player(gamerTag, membershipType) {
  let encodedGamertag = encodeURIComponent(gamerTag);
  if (membershipType == 3) {
    //  For Steam IDs, only Steam ID is needed (membership type 3)
    return get(
      `/User/GetMembershipFromHardLinkedCredential/SteamId/${encodedGamertag}/`
    ).then(players => {
      if (players.membershipId) {
        return players;
      } else {
        throw new ApiError(
          "Response did not match expected format: No membershipId found",
          response
        );
      }
    });
  } else {
    //  For gamertags on platforms xbox, psn, stadia, etc
    return get(
      `/Destiny2/SearchDestinyPlayer/${membershipType}/${encodedGamertag}/`
    ).then(players => {
      if (players.length < 1) {
        throw new ApiError("No players found", players);
      } else if (players.length > 1) {
        throw new ApiError("Multiple players found", players);
      } else {
        return players[0];
      }
    });
  };
}

export function getDestiny2Characters(membershipId, membershipType) {
  return get(`/Destiny2/${membershipType}/Profile/${membershipId}/`, {
    components: 200
  }).then(response => {
    if (response.characters && response.characters.data) {
      return response.characters.data;
    } else {
      throw new ApiError(
        "Response did not match expected format: No characters or character data",
        response
      );
    }
  });
}
