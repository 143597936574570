import React from "react";
import * as api from "../../utils/api";
import UserFeedItem from "../UserFeedItem";
import Loading from "../Loading";


export default class UserFeed extends React.Component {

  state = {
    loading: true
  }

  componentDidMount() {
    this.fetchUserFeedItems()
  }

  fetchUserFeedItems = () => {
    api.fetchUserFeedItems({ userId: this.props.userId, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      console.log(response)
      this.setState({ feedItems: response.data, loading: false })
    })
  }



  render() {
    if (this.state.loading) {
      return (
        <div className="gap-xy-1 my-4 text-center" style={{ height: "50" }}>
          <Loading />
        </div>
      )
    }

    return (
      <div>
        {this.state.feedItems.map((item) =>
          <div>
            <UserFeedItem userId={this.props.userId} isAuthorized={this.props.isAuthorized} item={item} />
          </div>
        )}
      </div>
    )
  }
}