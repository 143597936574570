import React from "react";
import PropTypes from "prop-types";
import { MentionsInput, Mention } from "react-mentions";

// import styles from "./styles.css";
import "./styles.css";


export default class MessageInput extends React.Component {
  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string
  };

  static defaultProps = {
    onChange: null,
    value: ""
  };

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value
    };
  }

  onChange = (e, value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(e, value);
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.value !== this.state.value) {
      this.setState({ value: newProps.value });
    }
  }

  render() {
    return (
      <MentionsInput
        className="input"
        singleLine={true}
        displayTransform={username => "@" + username}
        value={this.state.value}
        onChange={this.onChange}
      >
        <Mention
          trigger="@"
          data={this.props.users.map(u => ({ id: u, display: u }))}
          className="input__mention"
          markup="@[__display__]"
        />
      </MentionsInput>
    );
  }
}
