import React from "react";
import PropTypes from "prop-types";

// import "./styles.css";

// import message from "../Message/MessageBody/styles.css";

import "../Message/MessageBody/styles.css";


export default class ChatFormattingHelp extends React.Component {
  render() {
    const bold = {
      fontWeight: "bold"
    };

    return (
      <div>
        <div className={"chat-formatting-help-toggle"}>
          <a data-toggle="modal" href="#chat-formatting-help-modal">
            <small>show formatting help</small>
          </a>
        </div>
        <div
          className={"chat-formatting-help-modal"}
          id="chat-formatting-help-modal"
          className="modal fade text-dark"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Chat Formatting Help</h4>
              </div>
              <div className="modal-body">
                <table className="table table-condensed">
                  <tbody>
                    <tr>
                      <td>
                        '@' followed by a user's gamertag:
                        <br />
                        @FearBroner
                      </td>
                      <td>
                        <a className="text-dark" href="/users/FearBroner">@FearBroner</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Wrap the username in brackets if it contains a space or
                        '#':
                        <br />
                        @[Pac Man]
                      </td>
                      <td>
                        <a className="text-dark" href="/users/Pac Man">@Pac Man</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Emoji (see{" "}
                        <a
                          className="text-dark"
                          href="https://www.webpagefx.com/tools/emoji-cheat-sheet/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Emoji Cheat Sheet
                        </a>)
                        <br />
                        :smile:
                      </td>
                      <td>😄</td>
                    </tr>
                    <tr>
                      <td>~~spoiler text~~</td>
                      <td>
                        <span className="spoiler">spoiler text</span>
                      </td>
                    </tr>
                    <tr>
                      <td>_underlined text_</td>
                      <td>
                        <span className="underline">
                          underlined text
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>**bold text**</td>
                      <td>
                        <span style={bold}>bold text</span>
                      </td>
                    </tr>
                    <tr>
                      <td>*italicized text*</td>
                      <td>
                        <span className="italics">italicized text</span>
                      </td>
                    </tr>
                    <tr>
                      <td>superscript ^text^</td>
                      <td>
                        superscript <sup>text</sup>
                      </td>
                    </tr>
                    <tr>
                      <td>-strikethrough text-</td>
                      <td>
                        <span className="strike-through">
                          strikethrough text
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>`code`</td>
                      <td>
                        <code className="code">code</code>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
