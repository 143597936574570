// import PropTypes from 'prop-types';
import React from "react";
import ReactOnRails from "react-on-rails";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "./styles.css";

import Loading from "../Loading";
import * as api from "../../utils/api";

export default class GamingSessionForm extends React.Component {
  state = {
    crossplay: this.props.crossplay || false,
    createdFrom: (this.props.gaming_session && this.props.gaming_session.created_from) || "website-v2",
    platformsList: [
      { label: "XBOX", value: "xbox-one" },
      { label: "PSN", value: "ps4" },
      { label: "PC", value: "pc" },
      { label: "STADIA", value: "stadia" },
    ],
    platform: this.props.gaming_session
      ? { label: "", value: this.props.gaming_session.platform }
      : this.props.platform,
    gamesData: {},
    gamesList: "",
    activitiesList: null,
    selectedGame: null,
    selectedActivity: this.props.gaming_session && {
      label: this.props.gaming_session.category,
      value: this.props.gaming_session.category,
    },
    description: this.props.gaming_session && this.props.gaming_session.name ? this.props.gaming_session.name : "",
    lightLevel:
      this.props.gaming_session && this.props.gaming_session.light_level ? this.props.gaming_session.light_level : "",
    when: this.props.gaming_session ? (this.props.gaming_session.start_asap ? "asap" : "scheduled") : null,
    displayWhenHelp: false,
    startDate: this.props.gaming_session ? Date.parse(this.props.gaming_session.start_time) : new Date(),
    selectedGroup: this.props.group || (this.props.groups && this.props.groups[0]),
    displayVisibilityHelp: false,
    publicVisible: this.props.gaming_session
      ? this.props.gaming_session.public_visible
      : localStorage.getItem("publicVisible") == "true"
      ? true
      : false,
    allianceVisible: this.props.gaming_session
      ? this.props.gaming_session.alliance_visible
      : localStorage.getItem("allianceVisible") == "true"
      ? true
      : false,
    groupVisible: this.props.gaming_session
      ? this.props.gaming_session.group_visible
      : localStorage.getItem("groupVisible") == "true"
      ? true
      : false,
    friendsVisible: this.props.gaming_session
      ? this.props.gaming_session.friends_visible
      : localStorage.getItem("friendsVisible") == "true"
      ? true
      : false,
    privateVisible: this.props.gaming_session
      ? this.props.gaming_session.private_visible
      : localStorage.getItem("privateVisible") == "true"
      ? true
      : false,
    beginnersWelcome: this.props.gaming_session ? this.props.gaming_session.beginners_welcome : false,
    sherpaRequested: this.props.gaming_session ? this.props.gaming_session.sherpa_requested : false,
    headsetsRequired: this.props.gaming_session ? this.props.gaming_session.mic_required : true,
    discordInviteLink: this.props.gaming_session ? this.props.gaming_session.discord_invite_link : "",
    delayPosting: this.props.gaming_session ? this.props.gaming_session.delay_game_visibility : false,
    autoPublic: this.props.gaming_session ? this.props.gaming_session.make_auto_public : false,
    autoAlliance: this.props.gaming_session ? this.props.gaming_session.make_auto_alliance_visible : false,
    partySize: this.props.gaming_session ? this.props.gaming_session.party_size : "1",
    displayPartySizeHelp: false,
    displayPartySizeDecreaseWarning: false,
    displayPartySizeIncreaseWarning: false,
    displaySupporterMessage: false,
    // teamSizes: [...Array(100).keys()].map(i => { return { label: i, value: i } }),
    // teamSize: this.props.gaming_session ? { label: this.props.gaming_session.team_size, value: this.props.gaming_session.team_size } : null,
    teamSize: this.props.gaming_session ? this.props.gaming_session.team_size : null,
    step: 1,
    errors: "",
    loading: true,
    gamingSession: this.props.gaming_session,
  };

  componentDidMount() {
    api.fetchGames().then((response) => {
      var menuItems = response.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      let selectedGame = this.state.gamingSession
        ? menuItems.find((x) => x.value == this.state.gamingSession.game_id)
        : menuItems.find((x) => x.value == this.props.gameId);

      this.setState(
        {
          gamesData: response,
          gamesList: menuItems,
          selectedGame: selectedGame,
        },
        () =>
          this.setState({
            activitiesList: this.fetchActivities(this.state.selectedGame),
            loading: false,
          })
      );
    });
  }

  updatePlatform = ({ platform }) => {
    this.setState({ platform });
  };

  updatePartySize = (partySize) => {
    if (
      this.props.gaming_session &&
      this.props.hasWaitlist &&
      this.props.gaming_session.party_size != partySize &&
      partySize < this.state.partySize
    ) {
      this.setState({
        partySize,
        displayPartySizeDecreaseWarning: true,
        displayPartySizeIncreaseWarning: false,
      });
    } else if (
      this.props.gaming_session &&
      this.props.isFull &&
      this.props.gaming_session.party_size != partySize &&
      partySize > this.state.partySize
    ) {
      this.setState({
        partySize,
        displayPartySizeIncreaseWarning: true,
        displayPartySizeDecreaseWarning: false,
      });
    } else {
      this.setState({
        partySize,
        displayPartySizeIncreaseWarning: false,
        displayPartySizeDecreaseWarning: false,
      });
    }
  };

  updateWhen = (when) => {
    this.setState({ when });
  };

  setStartDate = (startDate) => {
    this.setState({ startDate });
  };

  toggleAutoPublic = () => {
    if (this.state.publicVisible) {
      this.setState({
        autoPublicError: this.state.autoPublicError ? null : "This game is already publicly visible.",
      });
    } else {
      this.setState({ autoPublic: !this.state.autoPublic });
    }
  };

  toggleAutoAlliance = () => {
    if (this.state.allianceVisible) {
      this.setState({
        autoAllianceError: this.state.autoAllianceError ? null : "This game is already visible to your alliance.",
      });
    } else {
      this.setState({ autoAlliance: !this.state.autoAlliance });
    }
  };

  togglePublicVisible = () => {
    localStorage.setItem("publicVisible", !this.state.publicVisible);

    this.setState({ publicVisible: !this.state.publicVisible }, () => {
      this.setState({
        privateVisible: this.state.publicVisible ? false : this.state.privateVisible,
      });
      localStorage.setItem("privateVisible", this.state.publicVisible ? false : this.state.privateVisible);
    });
  };

  toggleAllianceVisible = () => {
    if (!this.props.group && !this.props.groups) {
      this.setState({
        noGroupsError: this.state.noGroupsError ? null : "You're not currently a member of any groups or alliances.",
      });
    } else {
      localStorage.setItem("allianceVisible", !this.state.allianceVisible);
      this.setState({ allianceVisible: !this.state.allianceVisible }, () => {
        this.setState({
          privateVisible: this.state.allianceVisible ? false : this.state.privateVisible,
          groupVisible: this.state.allianceVisible ? true : this.state.groupVisible,
        });
        localStorage.setItem("privateVisible", this.state.allianceVisible ? false : this.state.privateVisible);
        localStorage.setItem("groupVisible", this.state.allianceVisible ? true : this.state.groupVisible);
      });
    }
  };

  toggleGroupVisible = () => {
    if (!this.props.group && !this.props.groups) {
      this.setState({
        noGroupsError: this.state.noGroupsError ? null : "You're not currently a member of any groups or alliances.",
      });
    } else {
      localStorage.setItem("groupVisible", !this.state.groupVisible);

      this.setState({ groupVisible: !this.state.groupVisible }, () => {
        this.setState({
          privateVisible: this.state.groupVisible ? false : this.state.privateVisible,
          allianceVisible: this.state.groupVisible ? this.state.allianceVisible : false,
        });

        localStorage.setItem("privateVisible", this.state.groupVisible ? false : this.state.privateVisible);
        localStorage.setItem("allianceVisible", this.state.groupVisible ? this.state.allianceVisible : false);
      });
    }
  };

  toggleFriendsVisible = () => {
    localStorage.setItem("friendsVisible", !this.state.friendsVisible);

    this.setState({ friendsVisible: !this.state.friendsVisible }, () => {
      this.setState({
        privateVisible: this.state.friendsVisible ? false : this.state.privateVisible,
      });
      localStorage.setItem("privateVisible", !this.state.privateVisible);
    });
  };

  togglePrivateVisible = () => {
    if (this.state.privateVisible) {
      this.setState({ privateVisible: false });
      localStorage.setItem("privateVisible", false);
    } else {
      this.setState({
        privateVisible: true,
        publicVisible: false,
        allianceVisible: false,
        groupVisible: false,
        friendsVisible: false,
      });
      localStorage.setItem("privateVisible", true);
      localStorage.setItem("publicVisible", false);
      localStorage.setItem("allianceVisible", false);
      localStorage.setItem("groupVisible", false);
      localStorage.setItem("friendsVisible", false);
    }
  };

  setStep = (step) => {
    this.setState({ step });
  };

  fetchActivities = (selected) => {
    // return if selected game is null
    if (!selected) {
      return [];
    }
    let selectedGame = this.state.gamesData.find((x) => x.id == selected.value);
    let activities = selectedGame.activities.sort().map((name) => ({ value: name, label: name }));
    return activities;
  };

  handleChangeGame = (selectedGame) => {
    let activities = this.fetchActivities(selectedGame);
    this.setState(
      {
        selectedGame,
        activitiesList: activities,
        selectedActivity: null,
        loading: true,
      },
      () => this.setState({ loading: false })
    );
  };

  handleChangeActivity = (selectedActivity) => {
    this.setState({ selectedActivity });
  };

  handleChangeGroup = (selectedGroup) => {
    this.setState({ selectedGroup });
  };

  // handleChangeTeamSize = teamSize => {
  //   this.setState(
  //     { teamSize })
  // };

  handleOnClick = (onClick) => {
    onClick();
    this.updateWhen("scheduled");
  };

  formReady = () => {
    const {
      selectedGame,
      selectedActivity,
      when,
      publicVisible,
      allianceVisible,
      groupVisible,
      friendsVisible,
      privateVisible,
      teamSize,
    } = this.state;

    let who = publicVisible || allianceVisible || groupVisible || friendsVisible || privateVisible;

    let teamSizeSelected =
      !selectedActivity ||
      (selectedActivity && selectedActivity.value != "Other") ||
      (selectedActivity && selectedActivity.value == "Other" && teamSize);

    return when && who && teamSizeSelected ? true : false;
  };

  onSubmit = () => {
    const {
      createdFrom,
      selectedGame,
      platform,
      description,
      lightLevel,
      selectedActivity,
      startDate,
      when,
      selectedGroup,
      beginnersWelcome,
      sherpaRequested,
      headsetsRequired,
      partySize,
      teamSize,
      discordInviteLink,
      publicVisible,
      allianceVisible,
      groupVisible,
      friendsVisible,
      privateVisible,
      delayPosting,
      autoPublic,
      autoAlliance,
    } = this.state;

    if (!this.formReady()) {
      return this.setState({
        formReadyError: "Make sure you've picked an activity, start time, and who can view the game.",
      });
    }

    this.setState({ loading: true, errors: "" });

    // TODO Rails DB field names for description and activity need to be updated, currently 'name' and 'category'
    let data = {
      gaming_session_id: this.props.gaming_session && this.props.gaming_session.id,
      game_id: selectedGame && selectedGame.value,
      platform: platform.value,
      name: description,
      category: selectedActivity.value,
      light_level: lightLevel,
      start_time: Number.isInteger(startDate) ? this.props.gaming_session.start_time : startDate,
      start_asap: when == "asap" ? true : false,
      group_id: groupVisible ? selectedGroup.value : null,
      beginners_welcome: beginnersWelcome,
      sherpa_requested: sherpaRequested,
      mic_required: headsetsRequired,
      discord_invite_link: discordInviteLink,
      party_size: partySize,
      team_size: teamSize ? teamSize : null,
      created_from: createdFrom,
      public_visible: publicVisible,
      alliance_visible: allianceVisible,
      group_visible: groupVisible,
      friends_visible: friendsVisible,
      private_visible: privateVisible,
      delay_game_visibility: delayPosting,
      make_auto_public: autoPublic,
      make_auto_alliance_visible: autoAlliance,
    };

    console.log("DATA:");
    console.log(data);
    console.log(data.platform);

    if (this.props.gaming_session) {
      api
        .updateGamingSession({
          data,
          headers: ReactOnRails.authenticityHeaders(),
        })
        .then((response) => {
          if (response.status == 200) {
            let redirect = window.encodeURI("/gaming_sessions/" + response.data.gaming_session.id);
            window.location.href = redirect;
            this.setState({
              step: 4,
              loading: false,
              gamingSession: response.data.gaming_session,
            });
          } else {
            this.setState({ loading: false, errors: response.data.error });
          }
        })
        .catch((e) => {
          this.setState({
            loading: false,
            errors: "Error editing game - contact us.",
          });
        });
    } else {
      api
        .createGamingSession({
          data,
          headers: ReactOnRails.authenticityHeaders(),
        })
        .then((response) => {
          if (response.status == 201) {
            let redirect = window.encodeURI("/gaming_sessions/" + response.data.gaming_session.id);
            window.location.href = redirect;
            this.setState({
              step: 4,
              loading: false,
              gamingSession: response.data.gaming_session,
            });
          } else {
            this.setState({ loading: false, errors: response.data.error });
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
            errors: "Error creating game - contact us.",
          });
        });
    }
  };

  render() {
    const whenHelpMessage =
      "Click SCHEDULED to schedule a game in the future (recommended). Or click ASAP to post a game right away, and keep it displayed until it fills.";

    const visibilityHelpMessage =
      "PUBLIC: visible everywhere and notifies group and friends (recommended). ALLIANCE: visible on all allied group pages. GROUP: visible on group page. FRIENDS: visible to friends. PRIVATE: No notifications and not listed anywhere, only those with direct link can view.";

    const partySizeHelpMessage = "Select how many players you already have. If it's just you, leave this at 1.";

    const otherOptionsHelpMesssage =
      "BEGINNERS WELCOME: Makes game public and encourages new players to join. Also earns you Sherpa Points. SHERPA REQUESTED: Select this to request help from experienced players (sherpas) for this game.";

    const { selectedGame, selectedActivity, startDate, discordInviteLink } = this.state;

    if (this.state.loading) {
      return (
        <div>
          <h3>Loading...</h3>
          <h3></h3>
          <br />
          <div className="col-sm-12 col-xl-10 gap-xy-2">
            <div style={{ minHeight: "300px" }}>
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.state.errors && (
          <div className="pb-5">
            <div className="alert alert-danger" role="alert">
              {this.state.errors}
            </div>
          </div>
        )}

        {this.state.step == 1 && (
          <div className="mx-1">
            <h4 className="mb-3">What Game?</h4>
            <div className="form-group text-dark">
              <Select
                key="game"
                className=""
                options={this.state.gamesList}
                defaultValue={selectedGame}
                onChange={this.handleChangeGame}
              />
            </div>

            {this.state.selectedGame && (
              <div>
                <h4 className="mb-3">What Activity?</h4>
                <div className="form-group text-dark">
                  <Select
                    key="activity"
                    className=""
                    options={this.state.activitiesList}
                    defaultValue={selectedActivity}
                    onChange={this.handleChangeActivity}
                    placeholder="Select Activity..."
                  />
                </div>
              </div>
            )}

            <div className="form-group">
              <h4 className="mb-3">
                Description <small>(optional)</small>
              </h4>

              <input
                className={"form-control text-dark fw-500 " + (this.state.description.length >= 255 && " is-invalid")}
                type="text"
                value={this.state.description}
                onChange={(event) => this.setState({ description: event.target.value })}
                maxLength="255"
              />
              {this.state.description.length >= 255 && (
                <div className="invalid-feedback">Max length is 255 characters.</div>
              )}
            </div>

            <h4 className="mt-4 mb-3">When?</h4>
            <div className="gap-xy-1">
              <DatePicker
                className="form-control mt-3"
                selected={startDate}
                onChange={(date) => this.setStartDate(date)}
                showTimeSelect
                dateFormat="Pp"
                customInput={<CustomDateInput when={this.state.when} handleOnClick={this.handleOnClick} />}
              />
              <button
                type="button"
                className={
                  "btn btn-glass px-2 mr-2 mb-2 " + (this.state.when == "asap" ? "btn-primary" : "btn-outline-primary")
                }
                onClick={() => this.updateWhen("asap")}
              >
                ASAP
              </button>
              <button
                type="button"
                className={
                  "btn btn-glass px-2 mr-2 mb-2 " + (this.state.displayWhenHelp ? "btn-primary" : "btn-outline-primary")
                }
                onClick={() =>
                  this.setState({
                    displayWhenHelp: !this.state.displayWhenHelp,
                  })
                }
              >
                ?
              </button>
              {this.state.displayWhenHelp && <p>{whenHelpMessage}</p>}
            </div>

            <h4 className="mt-4 mb-3">Who?</h4>

            <div className="gap-xy-1 mb-4">
              <button
                className={
                  "btn btn-sm btn-glass px-2 mr-2 mb-2 " +
                  (this.state.publicVisible ? "  btn-primary" : " btn-outline-secondary")
                }
                onClick={() => this.togglePublicVisible()}
              >
                Public
              </button>

              <button
                className={
                  "btn btn-sm btn-glass px-2 mr-2 mb-2 " +
                  (this.state.allianceVisible ? "btn-primary" : "btn-outline-secondary")
                }
                onClick={() => this.toggleAllianceVisible()}
              >
                Alliance
              </button>

              <button
                type="button"
                className={
                  "btn btn-sm btn-glass px-2 mr-2 mb-2 " +
                  (this.state.groupVisible ? "btn-primary" : "btn-outline-secondary")
                }
                onClick={() => this.toggleGroupVisible()}
              >
                Group
              </button>

              <button
                type="button"
                className={
                  "btn btn-sm btn-glass px-2 mr-2 mb-2 " +
                  (this.state.friendsVisible ? "btn-primary" : "btn-outline-secondary")
                }
                onClick={() => this.toggleFriendsVisible()}
              >
                Friends
              </button>

              <button
                type="button"
                className={
                  "btn btn-sm btn-glass px-2 mr-2 mb-2 " +
                  (this.state.privateVisible ? "btn-primary" : "btn-outline-secondary")
                }
                onClick={() => this.togglePrivateVisible()}
              >
                Private
              </button>

              <button
                type="button"
                className={
                  "btn btn-sm btn-glass px-2 mr-2 mb-2 " +
                  (this.state.displayVisibilityHelp ? "btn-primary" : "btn-outline-secondary")
                }
                onClick={() =>
                  this.setState({
                    displayVisibilityHelp: !this.state.displayVisibilityHelp,
                  })
                }
              >
                ?
              </button>
              {this.state.displayVisibilityHelp && <p>{visibilityHelpMessage}</p>}

              {this.state.noGroupsError && <p className="text-danger">{this.state.noGroupsError}</p>}
            </div>

            {(this.state.groupVisible || this.state.allianceVisible) && (
              <div className="form-group text-dark">
                <Select
                  key="group"
                  className=""
                  options={this.props.groups}
                  defaultValue={this.state.selectedGroup}
                  onChange={this.handleChangeGroup}
                />
              </div>
            )}

            <BottomButtons
              step={1}
              setStep={this.setStep}
              onSubmit={this.onSubmit}
              formReady={this.formReady}
              formReadyError={this.state.formReadyError}
              gamingSession={this.props.gaming_session}
            />
          </div>
        )}

        {this.state.step == 2 && (
          <div className="mx-1">
            <h4 className="mb-3">Current Party Size</h4>
            <div className="gap-xy-1 mb-6">
              {["1", "2", "3", "4", "5", "6"].map((partySize) => (
                <button
                  key={partySize}
                  className={
                    "btn btn-glass px-2 mr-2 mb-2 " +
                    (this.state.partySize == partySize ? "btn-primary" : "btn-outline-primary")
                  }
                  onClick={() => this.updatePartySize(partySize)}
                >
                  {partySize}
                </button>
              ))}
              <button
                type="button"
                className={
                  "btn btn-glass px-2 mr-2 mb-2 " +
                  (this.state.displayPartySizeHelp ? "btn-primary" : "btn-outline-primary")
                }
                onClick={() =>
                  this.setState({
                    displayPartySizeHelp: !this.state.displayPartySizeHelp,
                  })
                }
              >
                ?
              </button>

              {this.state.displayPartySizeDecreaseWarning && (
                <p className="text-orange">
                  WARNING: this game has players on waitlist! If you lower the party size, they'll be automatically
                  promoted to an active player and notified.
                </p>
              )}

              {this.state.displayPartySizeIncreaseWarning && (
                <p className="text-orange">
                  WARNING: this game is already full! If you increase the party size, you'll have to kick one of the
                  players who've already joined.
                </p>
              )}

              {this.state.displayPartySizeHelp && <p>{partySizeHelpMessage}</p>}
            </div>

            {(!this.state.selectedGame ||
              (this.state.selectedActivity && this.state.selectedActivity.value == "Other")) && (
              <div>
                <h4 className="mb-3">
                  Max Total Players <small>(optional)</small>
                </h4>

                <div className="form-group text-dark">
                  <input
                    className={"form-control text-dark fw-500 " + (this.state.teamSize >= 100 && " is-invalid")}
                    style={{ width: 100 }}
                    type="text"
                    value={this.state.teamSize}
                    onChange={(event) => this.setState({ teamSize: event.target.value })}
                    maxLength="100"
                  />
                  {this.state.teamSize > 100 && <div className="invalid-feedback">Max team size is 100 people.</div>}
                </div>
              </div>
            )}

            <h4>Platform</h4>
            {this.state.crossplay && <i>crossplay is enabled for this game</i>}
            <div className="gap-xy-1 mb-6 mt-1">
              {this.state.platformsList.map((platform) => (
                <button
                  key={platform.value}
                  className={
                    "btn btn-glass px-2 mr-2 mb-2 " +
                    (this.state.platform.value == platform.value || this.state.crossplay
                      ? "btn-primary"
                      : "btn-outline-primary")
                  }
                  onClick={() => this.updatePlatform({ platform })}
                >
                  {platform.label}
                </button>
              ))}
            </div>

            {this.state.selectedGame && this.state.selectedGame.label == "Destiny 2" && (
              <div className="form-group">
                <h4 className="mb-3">Required Power Level</h4>
                <input
                  className={"form-control text-dark fw-500 " + (this.state.lightLevel.length >= 10 && " is-invalid")}
                  style={{ width: 100 }}
                  type="text"
                  value={this.state.lightLevel}
                  onChange={(event) => this.setState({ lightLevel: event.target.value })}
                  maxLength="255"
                />
                {this.state.lightLevel.length >= 255 && (
                  <div className="invalid-feedback">Max length is 10 characters.</div>
                )}
              </div>
            )}

            <h4 className="mt-4 mb-3">Other Options</h4>
            <div className="gap-xy-1 mb-6">
              <button
                className={
                  "btn btn-glass px-2 mr-2 mb-2 " +
                  (this.state.beginnersWelcome ? "  btn-primary" : " btn-outline-primary")
                }
                onClick={() =>
                  this.setState(
                    {
                      loading: true,
                      beginnersWelcome: !this.state.beginnersWelcome,
                    },
                    () =>
                      this.setState({
                        sherpaRequested: this.state.beginnersWelcome == true ? false : this.state.sherpaRequested,
                        loading: false,
                      })
                  )
                }
              >
                Beginners Welcome
              </button>
              <button
                className={
                  "btn btn-glass px-2 mr-2 mb-2 " + (this.state.sherpaRequested ? "btn-primary" : "btn-outline-primary")
                }
                onClick={() =>
                  this.setState(
                    {
                      loading: true,
                      sherpaRequested: !this.state.sherpaRequested,
                    },
                    () =>
                      this.setState({
                        beginnersWelcome: this.state.sherpaRequested == true ? false : this.state.beginnersWelcome,
                        loading: false,
                      })
                  )
                }
              >
                Sherpa Requested
              </button>
              <button
                type="button"
                className={
                  "btn btn-glass px-2 mr-2 mb-2 " +
                  (this.state.headsetsRequired ? "btn-primary" : "btn-outline-primary")
                }
                onClick={() =>
                  this.setState(
                    {
                      loading: true,
                      headsetsRequired: !this.state.headsetsRequired,
                    },
                    () => this.setState({ loading: false })
                  )
                }
              >
                Headset Required
              </button>
              <button
                type="button"
                className={
                  "btn btn-glass px-2 mr-2 mb-2 " +
                  (this.state.displayOtherOptionsHelp ? "btn-primary" : "btn-outline-primary")
                }
                onClick={() =>
                  this.setState({
                    displayOtherOptionsHelp: !this.state.displayOtherOptionsHelp,
                  })
                }
              >
                ?
              </button>
              {this.state.displayOtherOptionsHelp && <p>{otherOptionsHelpMesssage}</p>}
            </div>
            <div className="form-group">
              <label>Discord Invite Link</label>
              <input
                className="form-control text-dark fw-500"
                type="text"
                value={this.state.discordInviteLink}
                onChange={(event) => this.setState({ discordInviteLink: event.target.value })}
              />
              {discordInviteLink && !discordInviteLink.includes("https://") && (
                <p className="text-danger">URL must include https://</p>
              )}
            </div>

            <BottomButtons
              step={2}
              setStep={this.setStep}
              onSubmit={this.onSubmit}
              formReady={this.formReady}
              formReadyError={this.state.formReadyError}
              gamingSession={this.props.gaming_session}
            />
          </div>
        )}

        {this.state.step == 3 && (
          <div className="mx-1">
            <h4 className="mb-3">Supporter Options</h4>

            {this.props.isSupporter ? (
              <div className="mb-6">
                <button
                  className={
                    "btn btn-glass btn-block mb-4 " + (this.state.autoPublic ? "  btn-warning" : " btn-outline-warning")
                  }
                  onClick={() => this.toggleAutoPublic()}
                >
                  Open To Public If Not Full 2 Hours Before Start
                </button>
                {this.state.autoPublicError && <p className="text-danger">{this.state.autoPublicError}</p>}

                <button
                  className={
                    "btn btn-glass btn-block mb-4 " + (this.state.autoAlliance ? "btn-warning" : "btn-outline-warning")
                  }
                  onClick={() => this.toggleAutoAlliance()}
                >
                  Open To Alliance If Not Full 2 Hours Before Start
                </button>
                {this.state.autoAllianceError && <p className="text-danger">{this.state.autoAllianceError}</p>}

                <button
                  type="button"
                  className={
                    "btn btn-glass btn-block mb-4 " + (this.state.delayPosting ? "btn-warning" : "btn-outline-warning")
                  }
                  onClick={() =>
                    this.setState({ loading: true, delayPosting: !this.state.delayPosting }, () =>
                      this.setState({ loading: false })
                    )
                  }
                >
                  Delay Posting For 1 Hour (so you can add friends first)
                </button>
              </div>
            ) : (
              <div className="mb-6">
                {this.state.displaySupporterMessage && (
                  <div className="mb-2">
                    <p className="text-warning">
                      These options are only available to our incredible supporters:
                      <br />
                      <a target="_blank" href={"/supporters"}>
                        {" "}
                        Learn more (opens new window)
                      </a>
                    </p>
                  </div>
                )}

                <button
                  className={
                    "btn btn-glass btn-block mb-4 " + (this.state.autoPublic ? "  btn-warning" : " btn-outline-warning")
                  }
                  onClick={() => this.setState({ displaySupporterMessage: true })}
                >
                  Open To Public If Not Full 2 Hours Before Start
                </button>

                <button
                  className={
                    "btn btn-glass btn-block mb-4 " + (this.state.autoAlliance ? "btn-warning" : "btn-outline-warning")
                  }
                  onClick={() => this.setState({ displaySupporterMessage: true })}
                >
                  Open To Alliance If Not Full 2 Hours Before Start
                </button>
                {this.state.autoAllianceError && <p className="text-danger">{this.state.autoAllianceError}</p>}

                <button
                  type="button"
                  className={
                    "btn btn-glass btn-block mb-4 " + (this.state.delayPosting ? "btn-warning" : "btn-outline-warning")
                  }
                  onClick={() => this.setState({ displaySupporterMessage: true })}
                >
                  Delay Posting For 1 Hour (so you can add friends first)
                </button>
              </div>
            )}

            <BottomButtons
              step={3}
              setStep={this.setStep}
              onSubmit={this.onSubmit}
              formReady={this.formReady}
              formReadyError={this.state.formReadyError}
              gamingSession={this.props.gaming_session}
            />
          </div>
        )}

        {this.state.step == 4 && (
          <div className="mx-1">
            <h4 className="mb-3">Game Created!</h4>
            <a href={`/gaming_sessions/${this.state.gamingSession.id}`}>View Gaming Session</a>
          </div>
        )}
      </div>
    );
  }
}

const CustomDateInput = ({ value, onClick, when, handleOnClick }) => (
  <button
    className={"btn btn-glass px-2 mr-2 mb-2 " + (when == "scheduled" ? "btn-primary" : "btn-outline-primary")}
    onClick={() => handleOnClick(onClick)}
  >
    {when == "scheduled" ? value : "SCHEDULED"}
  </button>
);

const BottomButtons = ({ step, setStep, onSubmit, formReady, formReadyError, gamingSession }) => {
  return (
    <div>
      <div className="row mt-6 pt-6 mb-4">
        <div className="col-6">
          {step == 3 ? (
            <button type="button" className="btn btn-sm btn-glass btn-block " onClick={() => setStep(1)}>
              <label className="mr-1">
                <i className="fa fa-chevron-left"></i>
              </label>
              Back
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-glass btn-block btn-outline-warning"
              onClick={() => setStep(3)}
            >
              <label className="mr-1">
                <i className="fa fa-fire"></i>
              </label>
              Supporter
            </button>
          )}
        </div>
        <div className="col-6">
          {step == 2 ? (
            <button type="button" className="btn btn-sm btn-glass btn-block " onClick={() => setStep(1)}>
              <label className="mr-1">
                <i className="fa fa-chevron-left"></i>
              </label>
              Back
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-glass btn-block btn-outline-secondary"
              onClick={() => setStep(2)}
            >
              <label className="mr-1">
                <i className="fa fa-gear"></i>
              </label>
              Advanced
            </button>
          )}
        </div>
      </div>

      <div className="mt-4 mb-3">
        {formReady() ? (
          <button type="button" className="btn btn-xl btn-glass btn-primary btn-block " onClick={() => onSubmit()}>
            {" "}
            {gamingSession ? "Update Gaming Session " : "Create Gaming Session "} &raquo;
          </button>
        ) : (
          <div>
            <button
              type="button"
              className="btn btn-xl btn-glass btn-outline-primary btn-block btn-disabled "
              onClick={() => onSubmit()}
            >
              {gamingSession ? "Update Gaming Session " : "Create Gaming Session "}
            </button>
            {formReadyError && <p className="text-danger">{formReadyError}</p>}
          </div>
        )}
      </div>
    </div>
  );
};
