import PropTypes from "prop-types";
import React from "react";
import ReactOnRails from "react-on-rails";

import Loading from "../Loading";
import * as api from "../../utils/api";

export default class GroupCreateForm extends React.Component {
  static propTypes = {
    form_authenticity_token: PropTypes.string,
    discord_signup_url: PropTypes.string,
    step: PropTypes.string,
    currentUserId: PropTypes.number,
    gameId: PropTypes.number,
  };

  state = {
    platformsList: ["xbox-one", "ps4", "pc", "stadia"],
    platform: "",
    ageRange: "",
    playSchedule: "",
    playScheduleList: [
      "Weekday Mornings and Weekends",
      "Weekday Afternoons and Weekends",
      "Weekday Evenings and Weekends",
      "Weekdays Latenight and Weekends",
    ],
    interestsList: [],
    interests: [],
    step: this.props.step || 1,
    errors: "",
    loading: false,
  };

  componentDidMount() {
    this.fetchAllTags();
    if (this.props.currentUserId && this.state.step == 1 && newTimeZone) {
      api.updateUser({
        headers: ReactOnRails.authenticityHeaders(),
        body: {
          user_id: this.props.currentUserId,
          time_zone: newTimeZone,
        },
      });
    }
  }

  fetchAllTags = () => {
    api.fetchAllGroupTags({ suggestedOnly: true }).then((response) => {
      console.log(response);
      this.setState({ interestsList: response });
    });
  };

  updatePlatform = (platform) => {
    this.setState({ platform, step: 2 });
  };

  updatePlaySchedule = (playSchedule) => {
    this.setState({ playSchedule, step: 3 });
  };

  updateAge = (ageRange) => {
    this.setState({ ageRange, step: 4 });
  };

  updateInterests = (interest) => {
    this.state.interests.includes(interest)
      ? this.setState({ interests: this.state.interests.filter((i) => i !== interest) })
      : this.setState({ interests: [...this.state.interests, interest] });
  };

  createGroupManually = () => {
    this.setState({ loading: true });
    api
      .createGroupManually({ gameId: this.props.gameId, headers: ReactOnRails.authenticityHeaders() })
      .then((response) => {
        if (response.status == 200 && response.data.error == "Existing Group Found") {
          this.setState({
            loading: false,
            errors:
              "It looks like you've already created a group. If you want to create more than one group, change the default name of your existing group then try again. Redirecting there shortly... ",
          });

          let redirect = window.encodeURI("/groups/" + response.data.group.id);

          setTimeout(() => {
            window.location.href = redirect;
            this.setState({ errors: nil });
          }, 8000);
        } else if (response.status == 201) {
          let redirect = window.encodeURI("/groups/" + response.data.group.id);
          window.location.href = redirect;
        } else {
          this.setState({ loading: false, errors: response.data.error });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loading: false, errors: "An error occurred, please double check your info and try again." });
      });
  };

  onSubmit = () => {
    if (this.props.currentUserId) {
      this.setState({ loading: true });

      api
        .updateUser({
          headers: ReactOnRails.authenticityHeaders(),
          body: {
            user_id: this.props.currentUserId,
            age: this.state.ageRange,
            hide_age: true,
            platform: this.state.platform,
            play_schedule: this.state.playSchedule,
            play_style: this.state.interests.includes("competitive") ? "serious" : "casual",
            time_zone: newTimeZone,
            tag_list: this.state.interests,
            tos_privacy_agreement: true,
            primary_game_id: this.props.gameId,
          },
        })
        .then((response) => {
          console.log(response);
          console.log(response.status);
          if (response.status == 200) {
            this.setState({ step: 5, loading: false });
          } else {
            this.setState({ loading: false, errors: response.data.error });
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false, errors: "An error occurred, please double check your info and try again." });
        });
    } else {
      this.setState({ step: 5 });
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <p className="lead">Processing... </p>
          <h3></h3>
          <br />
          <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
            <div style={{ minHeight: "300px" }}>
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.errors) {
      return (
        <div>
          <p className="lead text-danger">{this.state.errors} </p>
          <h3></h3>
          <br />
          <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
            <div style={{ minHeight: "300px" }}></div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.state.step == 1 && (
          <div>
            <p className="lead" data-font="Lato:700">
              {"looks like you're in the " + newTimeZone + " timezone."}
            </p>
            <h3>What platform does your group mostly play on?</h3>
            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              {this.state.platformsList.map((platform) => (
                <button type="button" className={"btn " + platform} onClick={() => this.updatePlatform(platform)}>
                  {platform}
                </button>
              ))}
            </div>
          </div>
        )}

        {this.state.step == 2 && (
          <div>
            <p className="lead" data-font="Lato:700">
              {"looks like you're in the " + newTimeZone + " timezone."}
            </p>
            <h3 data-font="Lato:700">When does your group usually play?</h3>
            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              {this.state.playScheduleList.map((playSchedule) => (
                <button
                  type="button"
                  className="btn btn-glass btn-dark"
                  onClick={() => this.updatePlaySchedule(playSchedule)}
                >
                  {playSchedule}
                </button>
              ))}
            </div>
            <br />
            <div className="d-inline">
              <button
                type="button"
                className="btn btn-disabled pull-left"
                onClick={() => this.setState({ step: 1, playSchedule: "" })}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
            </div>
          </div>
        )}

        {this.state.step == 3 && (
          <div>
            <p className="lead"></p>
            <h3 data-font="Lato:700">What's your group's rough age range?</h3>
            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("15")}>
                teens
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("25")}>
                twenties{" "}
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("35")}>
                thirties
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("45")}>
                forties
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("55")}>
                fifties
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("65")}>
                sixties
              </button>
              <button type="button" className="btn btn-glass btn-dark" onClick={() => this.updateAge("75")}>
                seventies +
              </button>
            </div>
            <br />
            <div className="d-inline">
              <button
                type="button"
                className="btn btn-disabled pull-left"
                onClick={() => this.setState({ step: 2, ageRange: "" })}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
            </div>
          </div>
        )}

        {this.state.step == 4 && (
          <div>
            <p className="lead"></p>
            <h3>Pick a few tags to describe your group (optional):</h3>
            <br />
            <p className="lead">Your interests: {this.state.interests.map((interest) => interest + ", ")}</p>
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              {this.state.interestsList.map((interest) => (
                <button
                  type="button"
                  className={"btn btn-glass " + (this.state.interests.includes(interest) ? "btn-primary" : "btn-dark")}
                  onClick={() => this.updateInterests(interest)}
                >
                  {interest}
                </button>
              ))}
            </div>
            <br />
            <br />
            <div className="d-inline">
              <button
                type="button"
                className="btn btn-disabled pull-left"
                onClick={() => this.setState({ step: 3, interests: [] })}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
              <button type="button" className="btn btn-primary pull-right" onClick={() => this.onSubmit()}>
                next
              </button>
            </div>
          </div>
        )}

        {this.state.step == 5 && (
          <div>
            <p className="lead"></p>
            <h3>Great! Now Let's Add Your Group:</h3>
            <br />
            <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
              <a href={this.props.discord_signup_url} type="button" className="btn btn-primary mr-2">
                Import Group From Discord
              </a>
              <button type="button" className="btn btn-primary" onClick={() => this.createGroupManually()}>
                Create New Group
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
