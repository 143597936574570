import React from "react";
import PropTypes from "prop-types";

import MessageInput from "../MessageInput";

import "./styles.css";

// import inputStyles from "../MessageInput/styles.css";

export default class MessageCreate extends React.Component {
  static propTypes = {
    createAllowed: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: ""
    };
  }

  onChange(e, value) {
    this.setState({ value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(this.state.value);
    this.setState({
      value: ""
    });
  }

  render() {
    if (this.props.createAllowed) {
      return (
        <form className="form" onSubmit={e => this.onSubmit(e)}>
          <div className="input-wrapper">
            <MessageInput
              onChange={(e, value) => this.onChange(e, value)}
              value={this.state.value}
              users={this.props.users}
            />
          </div>
          <button className="btn btn-primary submit">
            Chat
          </button>
        </form>
      );
    } else {
      return (
        <form className="form" onSubmit={e => e.preventDefault()}>
          <div className="input-wrapper">
            <div className="input">
              <input
                className="input__input"
                placeholder="Join first to chat!"
                disabled={true}
              />
            </div>
          </div>
          <button
            className="btn btn-disabled submit"
            disabled={true}
          >
            Chat
          </button>
        </form>
      );
    }
  }
}
