import React from "react";
import PropTypes from "prop-types";

import Loading from "../Loading";
import CharacterBanner from "../CharacterBanner";

import * as api from "../../utils/api";
import {
  getDestiny2Player,
  getDestiny2Characters
} from "../../utils/bungie_api";

export default class CharacterImporter extends React.Component {
  static propTypes = {
    gamertag: PropTypes.string.isRequired,
    membershipType: PropTypes.number.isRequired,
    confirmedSessionId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { characters: null };
  }

  componentWillMount() {
    getDestiny2Player(this.props.gamertag, this.props.membershipType)
      .then(player => {
        console.log("Player Found: ", player.membershipId)
        return getDestiny2Characters(
          player.membershipId,
          player.membershipType
        );
      })
      .then(characters => {
        this.setState({ characters: characters });
      })
      .catch(error => {
        console.error(
          "Failed to retrieve characters for CharacterImporter.",
          error
        );

        if (this.props.onError) {
          this.props.onError(error);
        }
      });
  }

  onCharacterSelected(character) {
    this.setState({ character });
    api
      .postCharacter(character, this.props.confirmedSessionId)
      .then(function (response) {
        window.location.reload();
      });
  }

  render() {
    if (!this.state.characters) {
      return (
        <div style={{ position: "relative", minHeight: "300px" }}>
          <Loading />
        </div>
      );
    } else {
      return (
        <div>
          {Object.values(this.state.characters).map(character => (
            <CharacterBanner
              key={character.characterId}
              character={character}
              onClick={c => this.onCharacterSelected(c)}
              hideEmblem={this.props.hideEmblem}
            />
          ))}
        </div>
      );
    }
  }
}
