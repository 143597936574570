import React from "react";
import * as api from "../../utils/api";


export default class GroupModIntro extends React.Component {

  state = {
    display: true,
  }


  render() {
    if (!this.state.display) {
      return null
    }

    return (
      <div className="col-md-12">
        <div className="media border border-glass rounded p-3 text-white bg-dark">
          <div className="card-body px-6">
            <h4 className="mb-4">Welcome To Your Group's Homepage!</h4>
            <p className="mb-4">1. Add all the games you play so we can match as many players as possible.</p>
            <p className="mb-4">2. Edit your group description below if you'd like.</p>
            <p className="mb-4">3. <a className="text-primary" href="" data-toggle="modal" data-show="true" data-target="#invite-v2">Invite your members </a> so everyone can start scheduling games. We prioritize groups by
    how many games they post.</p>
            <p className="mb-4">4. Schedule your first game with the <a className="text-primary" href="https://the100io.zendesk.com/hc/en-us/articles/360019030271-Commands-for-the-Discord-BOT" target="_blank"> discord bot </a> - just type !create to start.

            </p>
          </div>

          {this.props.isModerator ? (
            <div className="badges badges-right">
              <button type="button" className="btn btn-xs btn-glass btn-dark" onClick={() => this.setState({ display: false })}><i
                className="fa fa-times"></i></button>
            </div>) : null}
        </div>
      </div>
    )
  }


}