import PropTypes from 'prop-types';
import React from 'react';
import ReactOnRails from 'react-on-rails';
import moment from "moment";
import "moment-timezone";
import ReactTooltip from "react-tooltip";
import Loading from "../Loading";
import * as api from "../../utils/api";


export default class GameTime extends React.Component {
  static propTypes = {
    groupName: PropTypes.string,
    currentUserGamertag: PropTypes.string,
    groupId: PropTypes.number,
    timeZone: PropTypes.string,
    groupTimeZone: PropTypes.string
  };

  state = {
    timeSlots: {},
    timeSlotKeys: [],
    chosenTimeSlots: [],
    start_range: 12,
    end_range: 24,
    timeFormat: 'h a',
    loading: true,
  };

  componentDidMount() {
    this.fetchTimeSlots()
  }

  fetchTimeSlots = () => {
    api.fetchTimeSlots({ groupId: this.props.groupId, groupName: this.props.groupName, timeZone: newTimeZone, groupTimeZone: this.props.groupTimeZone }).then((response) => {
      let timeSlotKeys = Object.keys(response).slice(0, 7)
      let chosenTimeSlots = []

      timeSlotKeys.forEach(timeSlotKey => {
        response[timeSlotKey].forEach(timeSlot => {

          if (timeSlot.data != null && timeSlot.data["usernames"] != null && timeSlot.data["usernames"].includes(this.props.currentUserGamertag)) {
            chosenTimeSlots.push(timeSlot)
          }
        });
      })

      this.setState({ timeSlots: response, chosenTimeSlots: chosenTimeSlots, timeSlotKeys: timeSlotKeys, loading: false })
    })
  }


  updateTimeSlot = (timeSlot) => {
    if (this.state.chosenTimeSlots.includes(timeSlot)) {
      this.setState({ chosenTimeSlots: this.state.chosenTimeSlots.filter(t => t !== timeSlot) })
    } else {
      this.setState({ chosenTimeSlots: [...this.state.chosenTimeSlots, timeSlot] });
    }


    // let newTime = moment("2020-12-29T02:46:13Z").format();

    // let dayKey = moment(newTime).format("YYYY-MM-DD")

    // let newTimeSlot = this.state.timeSlots[dayKey].find(t => moment(t.start_time).format("HH") == moment(newTime).format("HH"))

    api.updateTimeSlot({ groupId: this.props.groupId, timeSlot: timeSlot, currentUserGamertag: this.props.currentUserGamertag, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      this.fetchTimeSlots()
    })
  };

  switchTimeFormat = () => {
    this.state.timeFormat == "h a" ? this.setState({ timeFormat: 'HH' }) : this.setState({ timeFormat: 'h a' })
  }



  render() {
    if (this.state.loading) {
      return (
        <div>
          <h4>Loading...</h4>

          <h3></h3>
          <br />
          <div className="col-10 col-md-12 col-xl-10 gap-xy-2">
            <div style={{ minHeight: "300px" }}>
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <ReactTooltip />

        <div className="container-fluid text-white ">
          <div className="row d-flex">
            <button className={"btn btn-xs  m-2 " + (this.state.start_range == 0 ? "btn-glass btn-primary" : "btn-outline  btn-outline-primary")} onClick={() => this.setState({ start_range: 0, end_range: 11 })}  >AM</button>

            <button className={"btn btn-xs  m-2 " + (this.state.start_range == 12 ? "btn-glass btn-primary" : "btn-outline  btn-outline-primary")} onClick={() => this.setState({ start_range: 12, end_range: 24 })} > PM</button>

            <button className={"btn btn-xs  m-2 " + (this.state.timeFormat != "h a" ? "btn-glass btn-primary" : "btn-outline  btn-outline-primary")} onClick={() => this.switchTimeFormat()} >24hr</button>

            {this.props.fullScreen ? null : (
              <a className="btn btn-outline btn-xs btn-outline-primary m-2" href={"/groups/" + this.props.groupId + "/gametime"}><i className="fa fa-expand"></i></a>
            )}
          </div>

          <div className="row d-flex">
            {this.state.timeSlotKeys.map(timeSlotKey => (
              <div key={timeSlotKey} className="col-1 pr-1 pl-1 no-gutter d-flex flex-column" style={{ minWidth: '14%' }}>
                <h5 className="text-center mb-0">{moment(timeSlotKey).format("ddd")} </h5>
                {
                  this.state.timeSlots[timeSlotKey].filter(t => moment(t.start_time).format("HH") >= this.state.start_range && moment(t.start_time).format("HH") <= this.state.end_range).map(timeSlot => (

                    <TimeSlot key={timeSlot.start_time} timeSlot={timeSlot} timeFormat={this.state.timeFormat} chosenTimeSlots={this.state.chosenTimeSlots} currentUserGamertag={this.props.currentUserGamertag} updateTimeSlot={this.updateTimeSlot} />
                  )
                  )
                }
              </div>

            ))}
          </div>
        </div>
      </div >
    );
  }
}

class TimeSlot extends React.Component {

  state = {
    deleting: false
  }

  componentDidMount() {
    ReactTooltip.rebuild()
  }

  updateTimeSlot = (timeSlot) => {
    if (this.props.chosenTimeSlots.includes(timeSlot)) {
      this.setState({ deleting: true })
      setTimeout(() => this.setState({ deleting: false }), 2000);
    }
    this.props.updateTimeSlot(timeSlot)
  }


  render() {

    const usernames = this.props.timeSlot.data != null && this.props.timeSlot.data["usernames"] != null && this.props.timeSlot.data["usernames"].length > 0 ? this.props.timeSlot.data["usernames"] : null
    let opacity = usernames ? (40 + (usernames.length * 10)) : 50
    let buttonStyle = usernames != null ? ' btn-primary' : ' btn-dark'
    let borderStyle = this.props.chosenTimeSlots.includes(this.props.timeSlot) ? ' border border-success' : this.state.deleting ? ' border border-danger' : ''

    return (
      < button data-tip={usernames} type="button" className={"mt-2 p-0 btn btn-glass btn-xs opacity-" + opacity + buttonStyle + borderStyle} onClick={() => this.updateTimeSlot(this.props.timeSlot)} >{moment(this.props.timeSlot.start_time).format(this.props.timeFormat)}</button>
    )
  }
}