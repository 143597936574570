import React from "react";
import * as api from "../../utils/api";


export default class ApprovedGames extends React.Component {

  state = {
    gamesList: [],
    approvedGames: [],
    editing: false,
    loading: true
  }

  componentDidMount() {
    api.fetchGames().then((response) => {
      var games = response.map(({ id, name }) => ({ id, name }));
      this.setState({ gamesList: games })
    })

    if (this.props.groupId) {
      api.fetchApprovedGames({ groupId: this.props.groupId }).then((response) => {
        var gameIds = response.map(({ game_id }) => (game_id));
        this.setState({ approvedGames: gameIds, loading: false })
      })
    } else {
      api.fetchUserApprovedGames({ userId: this.props.userId }).then((response) => {
        var gameIds = response.map(({ game_id }) => (game_id));
        this.setState({ approvedGames: gameIds, loading: false })
      })
    }

  }

  updateApprovedGames = (gameId) => {
    if (this.state.approvedGames.includes(gameId)) {
      this.setState({ approvedGames: this.state.approvedGames.filter(i => i !== gameId) })
    } else {
      this.setState({ approvedGames: [...this.state.approvedGames, gameId] })
    }

    if (this.props.groupId) {
      api.updateApprovedGames({ groupId: this.props.groupId, gameId: gameId, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      })
    } else {
      api.updateApprovedGames({ userId: this.props.userId, gameId: gameId, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      })
    }

  };



  render() {

    if (this.state.loading) {
      return (
        <div className="m-2">
          ...
        </div>
      )
    }

    return (
      <div>
        {this.state.editing ? (
          <div className="gap-xy-1">
            {this.state.gamesList.map(game => (
              < button type="button" className={"btn btn-xs btn-glass " + (this.state.approvedGames.includes(game.id) ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.updateApprovedGames(game.id)} key={game.id}>{game.name}</button>
            )
            )
            }
            < button type="button" className={"btn btn-xs btn-glass btn-success"} onClick={() => this.setState({ editing: !this.state.editing })} >done</button>

          </div>
        ) : (
            <div className="gap-xy-1">
              {this.state.gamesList.filter(game => (
                this.state.approvedGames.includes(game.id))).map(game => (
                  < button type="button" className={"btn btn-xs btn-glass btn-dark"} key={game.id}>{game.name}</button>
                )
                )
              }
              {this.props.isModerator ? (
                < button type="button" className={"btn btn-xs btn-glass btn-dark"} onClick={() => this.setState({ editing: !this.state.editing })} ><i className="fa fa-plus"></i></button>
              ) : null}

            </div>
          )}
      </div>
    )
  }
}