import React from "react";
import PropTypes from "prop-types";
import { Tweet } from "react-twitter-widgets";

import reactStringReplace from "react-string-replace-recursively";
import emoji from "node-emoji";

import "./styles.css";

function usernameMentionMatcherFn(rawText, processed, key) {
  let username = rawText;
  return (
    <a key={key} href={"/users/" + window.encodeURIComponent(username)}>
      @{username}
    </a>
  );
}

function urlMatcherFn(rawText, processed, key) {
  let url = rawText;
  return (
    <a key={key} href={url} target="_blank">
      {url}
    </a>
  );
}

const config = {
  spoiler: {
    pattern: /\B~~(.+?)~~\B/gim,
    matcherFn: (rawText, processed, key) => {
      return (
        <span key={key} className="spoiler">
          {processed}
        </span>
      );
    }
  },
  bold: {
    pattern: /\B\*\*(.+?)\*\*\B/gim,
    matcherFn: (rawText, processed, key) => {
      return (
        <span key={key} className="bold">
          {processed}
        </span>
      );
    }
  },
  italics: {
    pattern: /\B\*(.+?)\*\B/gim,
    matcherFn: (rawText, processed, key) => {
      return (
        <span key={key} className="italics">
          {processed}
        </span>
      );
    }
  },
  underline: {
    pattern: /\b_(.+?)_\b/gim,
    matcherFn: (rawText, processed, key) => {
      return (
        <span key={key} className="underline">
          {processed}
        </span>
      );
    }
  },
  superscript: {
    pattern: /\B\^(.+?)\^\B/gim,
    matcherFn: (rawText, processed, key) => {
      return <sup key={key}>{processed}</sup>;
    }
  },
  strikeThrough: {
    pattern: /\B-(\S.+?\S)-\B/gim,
    matcherFn: (rawText, processed, key) => {
      return (
        <span key={key} className="strike-through">
          {processed}
        </span>
      );
    }
  },
  code: {
    pattern: /\B`(.+?)`\B/gim,
    matcherFn: (rawText, processed, key) => {
      return (
        <code key={key} className="code">
          {processed}
        </code>
      );
    }
  },
  emoji: {
    pattern: /(:\w+?:)/gim,
    matcherFn: (rawText, processed, key) => {
      return emoji.get(rawText);
    }
  },

  userMention: {
    pattern: /\B@([a-z0-9_\-#]+?)\b/gim,
    matcherFn: usernameMentionMatcherFn
  },
  userMentionBracketed: {
    pattern: /\B@\[([a-z0-9_\-# ]+?)\]\B/gim,
    matcherFn: usernameMentionMatcherFn
  },
  gifv: {
    pattern: /(\bhttps?:\/\/\S+?\.gifv\b)/gim,
    matcherFn: (rawText, processed, key) => {
      return <MessageGifv key={key} source={rawText} />;
    }
  },
  image: {
    pattern: /(\bhttps?:\/\/\S+?\.(?:png|jpg|gif|jpeg)\b)/gim,
    matcherFn: (rawText, processed, key) => {
      return <MessageImage key={key} source={rawText} />;
    }
  },
  tweet: {
    pattern: /\bhttps?:\/\/twitter\.com\/(?:#!\/)?\w+\/status(?:es)?\/(\d+)\b/gim,
    matcherFn: (rawText, processed, key) => {
      return (
        <div key={key} className="block-item">
          <Tweet tweetId={rawText} />
        </div>
      );
    }
  },
  youtube: {
    pattern: /(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((?:\w|-){11})(?:\S+)?/gim,
    matcherFn: (rawText, processed, key) => {
      let videoId = rawText;
      return (
        <iframe
          key={key}
          className="block-item"
          src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`}
          frameBorder={0}
          allowFullScreen={false}
        />
      );
    }
  },
  url: {
    pattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/,
    matcherFn: urlMatcherFn
  }
};

const parseMessageText = reactStringReplace(config);

export default class MessageBody extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  };

  render() {
    let { text } = this.props;

    try {
      return parseMessageText(text);
    } catch (e) {
      console.error("Error parsing message text:", e);
    }

    return text;
  }
}

class MessageImage extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false
    };
  }

  onError = e => {
    this.setState({ error: true });
  };

  render() {
    let { source } = this.props;

    if (this.state.error) {
      return source;
    } else {
      return (
        <img
          className="block-item"
          src={source}
          alt={source}
          title={source}
          onError={this.onError}
        />
      );
    }
  }
}

class MessageGifv extends React.Component {
  static propTypes = {
    source: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false
    };
  }

  onError = e => {
    this.setState({ error: true });
  };

  render() {
    let { source } = this.props;

    if (this.state.error) {
      return source;
    } else {
      let sourceNoExt = source.replace(/\.gifv$/i, "");
      return (
        <video
          className="block-item"
          poster={sourceNoExt + ".jpg"}
          autoPlay={true}
          loop={true}
          preload="auto"
          muted={true}
          playsInline={true}
          alt={source}
          title={source}
          onError={this.onError}
        >
          <source
            src={sourceNoExt + ".mp4"}
            type="video/mp4"
            onError={this.onError}
          />
        </video>
      );
    }
  }
}
