import React from "react";
import moment from "moment";
import MessageBody from "../Chat/Message/MessageBody";



export default class FeedBody extends React.Component {

  render() {

    let startTime = ""
    if (this.props.item && (this.props.item.item_type == "player-joined-game" || this.props.item.item_type == "player-left-game") && this.props.item.data && this.props.item.data.gaming_session_start_time) {
      startTime = ` at ${moment(this.props.item.data.gaming_session_start_time).format("hh:mm A  MM/DD/YY")}`
    }

    if (this.props.iframeSrc) {
      return (
        <div style={{ height: this.props.iframeHeight + 10 }}>
          <iframe
            src={this.props.iframeSrc}
            width="100%" height={this.props.iframeHeight} allowtransparency="true" frameborder="0" scrolling="no"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </div>
      );
    } else if (this.props.item.body) {
      return (
        <div className="p-3">
          <MessageBody text={this.props.item.body + startTime} />
        </div>

      );
    } else {
      return (
        <Text>Error - Feed Item Id: {this.props.item.id}</Text>
      )
    }
  }
}


