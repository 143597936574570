import React from "react";
import * as api from "../../utils/api";


export default class PlatformMemberships extends React.Component {

  state = {
    platformsList: [
      { id: 1, name: 'Xbox' },
      { id: 2, name: 'PSN', },
      { id: 3, name: 'PC', },
      { id: 4, name: 'Stadia', }
    ],
    platformMemberships: [],
    editing: false,
    loading: true
  }

  componentDidMount() {

    api.fetchPlatformMemberships({ groupId: this.props.groupId }).then((response) => {
      var platformIds = response.map(({ platform_id }) => (platform_id));
      this.setState({ platformMemberships: platformIds, loading: false })
    })
  }

  updatePlatformMemberships = (platformId) => {
    if (this.state.platformMemberships.includes(platformId)) {
      this.setState({ platformMemberships: this.state.platformMemberships.filter(i => i !== platformId) })
    } else {
      this.setState({ platformMemberships: [...this.state.platformMemberships, platformId] })
    }

    api.updatePlatformMemberships({ groupId: this.props.groupId, platformId: platformId, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      console.log(response)
    })
  };



  render() {

    if (this.state.loading) {
      return (
        <div className="m-2">
          ...
        </div>
      )
    }

    return (
      <div>
        {this.state.editing ? (
          <div className="gap-xy-1">
            {this.state.platformsList.map(platform => (
              < button type="button" className={"btn btn-xs btn-glass " + (this.state.platformMemberships.includes(platform.id) ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.updatePlatformMemberships(platform.id)} key={platform.id}>{platform.name}</button>
            )
            )
            }
            < button type="button" className={"btn btn-xs btn-glass btn-success"} onClick={() => this.setState({ editing: !this.state.editing })} >done</button>

          </div>
        ) : (
            <div className="gap-xy-1">
              {this.state.platformsList.filter(platform => (
                this.state.platformMemberships.includes(platform.id))).map(platform => (
                  < button type="button" className={"btn btn-xs btn-glass btn-dark"} key={platform.id}>{platform.name}</button>
                )
                )
              }
              {this.props.isModerator ? (
                < button type="button" className={"btn btn-xs btn-glass btn-dark"} onClick={() => this.setState({ editing: !this.state.editing })} ><i className="fa fa-plus"></i></button>
              ) : null}

            </div>
          )}
      </div>
    )
  }
}