import React from "react";
import PropTypes from "prop-types";
import Chat from "../../components/Chat";

export default class GroupModsPage extends React.Component {
  static propTypes = {
    groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    token: PropTypes.string
  };

  render() {
    let room = `group-modchat-${this.props.groupId}`;

    return (
      <Chat
        token={this.props.token}
        url={`chat/group-modchats/${room}`}
        room={room}
        show_input={true}
      />
    );
  }
}
