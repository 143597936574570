import React from "react";
import PropTypes from "prop-types";

import MessageEdit from "../MessageEdit";
import MessageBody from "./MessageBody";
import TimeAgo from "../../TimeAgo";

import { ChatMessagePropType } from "../types";

import "./styles.css";

const DEFAULT_AVATAR_IMG = "https://www.the100.io/default-avatar.png";

export default class Message extends React.Component {
  static propTypes = {
    onRemoveMessage: PropTypes.func.isRequired,
    onEditMessage: PropTypes.func.isRequired,
    onStartEditMessage: PropTypes.func.isRequired,
    canEdit: PropTypes.bool.isRequired,
    message: PropTypes.shape(ChatMessagePropType).isRequired,
    users: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    editing: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      avatarUrl: this.props.message.avatarUrl
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.avatarUrl !== this.props.avatarUrl) {
      this.setState({ avatarUrl: nextProps.avatarUrl });
    }
  }

  onImgError = e => {
    if (this.state.avatarUrl !== DEFAULT_AVATAR_IMG) {
      this.setState({ avatarUrl: DEFAULT_AVATAR_IMG });
    }
  };

  onEditClick = e => {
    e.preventDefault();

    this.props.onStartEditMessage(this.props.message.key);
  };

  onRemoveClick = e => {
    e.preventDefault();

    this.props.onRemoveMessage(this.props.message.key);
  };

  renderEditing() {
    return (
      <div>
        <MessageEdit
          message={this.props.message}
          onSubmit={newText =>
            this.props.onEditMessage(this.props.message.key, newText)
          }
          users={this.props.users}
        />
      </div>
    );
  }

  renderNormal() {
    let userUrl =
      this.props.message.username === "guest"
        ? "#"
        : "/users/" + this.props.message.uid;

    let menu = !this.props.canEdit ? null : (
      <div className="dropdown" className="menu">
        <button
          className="dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          <span className="glyphicon glyphicon-option-vertical" />
        </button>
        <ul
          className="dropdown-menu dropdown-menu-right menu-dropdown p-3"
        >
          <li className="dropdown-item">
            <a className="text-dark" onClick={this.onEditClick}>Edit</a>
          </li>
          <li className="dropdown-item">
            <a className="text-dark" onClick={this.onRemoveClick}>Remove</a>
          </li>
        </ul>
      </div>
    );

    return (
      <li
        className={[
          "message",
          "role-" + this.props.message.role,
          this.props.message.edited ? "edited" : ""
        ].join(" ")}
      >
        <div className="user-avatar">
          <a href={userUrl}>
            <img
              className="img-responsive img-circle"
              src={this.state.avatarUrl}
              onError={this.onImgError}
            />
          </a>
        </div>
        <div className="message-content">
          <a href={userUrl} className="username">
            {this.props.message.username}
          </a>
          <TimeAgo className="time" date={this.props.message.createdAt} />
          {menu}
          <div className="message-body">
            <MessageBody text={this.props.message.text} />
          </div>
        </div>
      </li>
    );
  }

  render() {
    if (this.props.editing) {
      return this.renderEditing();
    } else {
      return this.renderNormal();
    }
  }
}
