import React from "react";


export default class Footer extends React.Component {


  render() {
    let avatars = null;
    let label = null;

    if (this.props.likeable) {
      avatars = this.props.item.related_users["likes"];
      label = "like";
    } else if (this.props.item.related_users && this.props.item.related_users.avatar_urls) {
      avatars = this.props.item.related_users.avatar_urls;
      label = "player";
    }
    const plural = avatars && avatars.length && avatars.length > 1 ? "s" : "";
    const clicked = avatars && avatars.includes(this.props.item.author_avatar_url) ? true : false
    const left =
      !avatars || avatars.length === 0
        ? 0
        : -5 * (avatars.length - 1) + 2;

    return (
      <div className="p-2">

        {avatars && avatars.length && (
          <div className="media">
            {avatars.map((url, index) => (
              <a className="align-self-start mr-3">
                <img className="avatar avatar-xs" src={
                  url === "img/default-avatar.png"
                    ? "https://www.the100.io/default-avatar.jpg"
                    : url
                } />
              </a>
            ))}
            <div className="footnote" style={{ left, alignSelf: "center" }}>{`${avatars.length
              } ${label + plural}`}</div>
          </div>

        )}
      </div>
    );
  }
}




