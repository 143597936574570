import React from "react";
import Header from "./Header"
import FeedImage from "./FeedImage";
import FeedBody from "./FeedBody"
import Footer from "./Footer"


export default class UserFeedItem extends React.Component {

  state = {
    loading: false,
    allTags: [],
    userTags: [],
    editing: false
  }

  componentDidMount() {

  }

  iframeSrc(item) {
    if (item.body && item.body.includes("iframe")) {
      if (item.body.match('src="(.*?)" ')) {
        return String(item.body.match('src="(.*?)" ').pop());
      }
    } else if (item.body && !item.body.includes("iframe") && item.body.includes("xboxdvr.com")) {
      return item.body;
    } else {
      return null
    }
  }

  iframeHeight(item) {
    if (item.body && item.body.includes("iframe") && item.body.match('height="(.*?)" ')) {
      return Number(item.body.match('height="(.*?)" ').pop());
    } else if (item.body && !item.body.includes("iframe") && item.body.includes("xboxdvr.com")) {
      return 260
    } else {
      return 300
    }
  }


  render() {




    return (
      <div>

        {/* <div class="media border border-glass rounded p-3 text-white bg-dark mb-3"> */}

        <a href={this.props.item.target_url} className="card border border-glass text-white d-block hover-shadow-6 mb-6 shadow-4" style={{ backgroundColor: 'rgb(35, 37, 41)' }}>

          <Header
            author_avatar_url={this.props.item.author_avatar_url}
            author_user_id={this.props.item.author_user_id}
            title={this.props.item.title}
            target_url={this.props.item.target_url}
            author_gamertag={this.props.item.author_gamertag}
            display_after={this.props.item.display_after} />

          <FeedImage item={this.props.item} imageUrl={this.props.item.image_url} />

          <FeedBody item={this.props.item} iframeSrc={this.iframeSrc(this.props.item)}
            iframeHeight={this.iframeHeight(this.props.item)} />
          {this.props.item.item_type != "player-left-game" &&

            <Footer
              item={this.props.item}
              likeable={false}
            />
          }
        </a>
      </div >
    )
  }
}