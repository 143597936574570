import React from "react";
import PropTypes from "prop-types";

import ChatFormattingHelp from "./ChatFormattingHelp";
import Message from "./Message";
import MessageCreate from "./MessageCreate";

import Tinycon from "tinycon";
import Visiblity from "visibilityjs";

import * as api from "../../utils/api";
import {
  signIn,
  getUserChatPermission,
  getUserChatRole
} from "../../utils/user";

import "./styles.css";

const NOTIFICATION_STARTUP_DELAY = 5000;
const NOTIFICATION_TIMEOUT = 5000;

Tinycon.setOptions({
  fallback: false
});

export default class Chat extends React.Component {
  static propTypes = {
    room: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    show_input: PropTypes.bool.isRequired,
    token: PropTypes.string,
    onMessageCreated: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      messages: {},
      text: "",
      uid: null,
      username: "guest",
      pwnmaster: false,
      role: "user",
      permission: "",
      avatarUrl: "/default-avatar.png",
      users: new Set(),
      editingKey: null,
      messageCount: 25
    };

    this.onLoadTime = new Date().getTime() + NOTIFICATION_STARTUP_DELAY;

    this.requestedNotificationPermission = false;
    this.lastNotification = null;

    this.unreadMessageCount = 0;
    this.visibilityId = null;

    this.messagesRef = firebase.database().ref(this.props.url);
    this.messagesQuery = null;
  }

  requestNotificationPermission() {
    if (!this.requestedNotificationPermission) {
      this.requestedNotificationPermission = true;

      if (window.Notification && window.Notification.permission !== "granted") {
        Notification.requestPermission().then(status => {
          console.log("Notification Permission:", status);
        });
      }
    }
  }

  updateFavicon() {
    Tinycon.setBubble(this.unreadMessageCount);
  }

  addMessage(dataSnapshot, adding) {
    let key = dataSnapshot.key;
    let message = dataSnapshot.val();
    message.key = key;

    this.setState(prevState => {
      let { messages, users } = prevState;
      messages[key] = message;
      users.add(message.username);
      return {
        messages: messages,
        users: users
      };
    });

    if (
      Visiblity.hidden() &&
      adding &&
      this.onLoadTime < message.createdAt &&
      this.state.uid !== message.uid
    ) {
      this.unreadMessageCount += 1;
      this.updateFavicon();

      if (window.Notification && window.Notification.permission === "granted") {
        if (this.lastNotification) {
          this.lastNotification.close();
        }

        let notification = new Notification(message.username, {
          body: message.text,
          icon: message.avatarUrl,
          requireInteraction: false
        });
        notification.addEventListener("click", () => {
          notification.close();
          window.focus();
        });
        setTimeout(() => notification.close(), NOTIFICATION_TIMEOUT);

        this.lastNotification = notification;
      }
    }
  }

  onMessageAdded = dataSnapshot => {
    this.addMessage(dataSnapshot, true);
  };

  onMessageChanged = dataSnapshot => {
    this.addMessage(dataSnapshot, false);
  };

  onMessageRemoved = dataSnapshot => {
    let key = dataSnapshot.key;
    this.setState(prevState => {
      let { messages } = prevState;
      delete messages[key];
      return { message: messages };
    });
  };

  retrieveMessages() {
    if (this.messagesQuery) {
      this.messagesQuery.off();
    }
    this.messagesQuery = this.messagesRef
      .orderByChild("createdAt")
      .limitToLast(this.state.messageCount);

    this.messagesQuery.on("child_added", this.onMessageAdded);
    this.messagesQuery.on("child_changed", this.onMessageChanged);
    this.messagesQuery.on("child_removed", this.onMessageRemoved);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);

    signIn(this.props.token, true)
      .then(user => {
        this.setState({
          uid: user.uid,
          username: user.username,
          pwnmaster: user.pwnmaster,
          role: getUserChatRole(user, this.props.room, "user"),
          permission: getUserChatPermission(user, this.props.room, "RW"),
          avatarUrl: user.avatar
        });

        this.retrieveMessages();
      })
      .catch(error => {
        console.error("An error occurred during sign in:", error);
      });

    this.visibilityId = Visiblity.change(() => {
      if (!Visiblity.hidden()) {
        this.unreadMessageCount = 0;
        this.updateFavicon();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.messageCount !== prevState.messageCount) {
      this.retrieveMessages();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);

    if (this.messagesQuery) {
      this.messagesQuery.off();
    }

    Visiblity.unbind(this.visibilityId);
    this.visibilityId = null;
  }

  onMessageCreate = text => {
    text = text && text.trim();
    if (text) {
      let newMessage = {
        text: text,
        username: this.state.username,
        avatarUrl: this.state.avatarUrl,
        uid: this.state.uid,
        role: this.state.role,
        createdAt: firebase.database.ServerValue.TIMESTAMP
      };
      this.messagesRef
        .push(newMessage)
        .then(() => {
          if (this.props.onMessageCreated) {
            this.props.onMessageCreated(newMessage);
          }
          let match = text.match(
            /\B@([a-z0-9_\-#]+?\b|\[[a-z0-9_\-# ]+?\]\B)/gim
          );
          if (match) {
            let recipientUsername = match[0].replace(/[@\[\]]/gim, "");
            api
              .postUsernameMention(
                this.props.url,
                this.state.username,
                recipientUsername,
                text
              )
              .catch(error => {
                console.error("Failed to post username mention:", error);
              });
          }
        })
        .catch(error => {
          console.error("Failed to send message:", error);
        });

      this.requestNotificationPermission();
    }
  };

  onStartEditMessage = key => {
    this.setState({ editingKey: key });
  };

  onEditMessage = (key, text) => {
    text = text && text.trim();
    if (text) {
      this.messagesRef
        .child(key)
        .update({
          text: text,
          edited: true,
          editedAt: firebase.database.ServerValue.TIMESTAMP
        })
        .catch(error => {
          console.error("Failed to edit message:", error);
        });
      this.setState({ editingKey: null });

      this.requestNotificationPermission();
    }
  };

  onKeyDown = e => {
    if (e.key === "Escape") {
      this.setState({ editingKey: null });
    }
  };

  onRemoveMessage = key => {
    this.messagesRef
      .child(key)
      .remove()
      .catch(error => {
        console.error("Failed to remove message:", error);
      });

    if (key === this.state.editingKey) {
      this.setState({ editingKey: null });

      this.requestNotificationPermission();
    }
  };

  onLoadMoreMessages = e => {
    this.setState(prevState => {
      let messageCount = prevState.messageCount + 25;
      return { messageCount: messageCount };
    });

    this.requestNotificationPermission();
  };

  render() {
    let users = Array.from(this.state.users);
    users.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    let messages = Object.entries(this.state.messages).sort(
      ([keyA, messageA], [keyB, messageB]) => {
        return messageB.createdAt - messageA.createdAt;
      }
    );

    let createAllowed =
      this.props.room.startsWith("game-") ||
      this.state.permission.includes("W");
    let showInput = this.props.show_input && this.state.uid;

    return (
      <div>
        <div className="chat">
          {!showInput ? null : (
            <MessageCreate
              createAllowed={createAllowed}
              users={users}
              onSubmit={this.onMessageCreate}
            />
          )}
          {!showInput || !createAllowed ? null : <ChatFormattingHelp />}
          <ul>
            {messages.map(([key, message]) => (
              <Message
                key={key}
                message={message}
                editing={key === this.state.editingKey}
                canEdit={
                  (this.state.uid && this.state.uid === message.uid) ||
                  this.state.permission.includes("E") ||
                  this.state.pwnmaster === true
                }
                users={users}
                onStartEditMessage={this.onStartEditMessage}
                onRemoveMessage={this.onRemoveMessage}
                onEditMessage={this.onEditMessage}
              />
            ))}
          </ul>
        </div>
        {messages.length < this.state.messageCount ? null : (
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={this.onLoadMoreMessages}
          >
            LOAD MORE MESSAGES
          </button>
        )}
      </div>
    );
  }
}
