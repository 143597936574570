import React from "react";
import PropTypes from "prop-types";
import Chat from "../../components/Chat";

export default class GamingSessionsPage extends React.Component {
  static propTypes = {
    gamingSessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    token: PropTypes.string
  };

  render() {
    let room = `game-${this.props.gamingSessionId}`;

    return (
      <Chat
        token={this.props.token}
        url={`chat/gaming_sessions/${room}`}
        room={room}
        show_input={true}
      />
    );
  }
}
