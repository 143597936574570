import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import "moment-timezone";

export default class TimeAgo extends React.Component {
  static propTypes = {
    date: PropTypes.number.isRequired
  };

  render() {
    let { date, ...rest } = this.props;
    date = moment(date);

    return (
      <time title={date.format("L [at] LT")} {...rest}>
        {date.calendar({
          sameElse: "L [at] LT"
        })}
      </time>
    );
  }
}
