import React from "react";
import PropTypes from "prop-types";
import * as api from "../../utils/api";


import "./styles.css";

const CLASS_TYPES = ["Titan", "Hunter", "Warlock", "Unknown"];

const RACE_TYPES = ["Human", "Awoken", "Exo", "Unknown"];

const GENDER_TYPES = ["Male", "Female", "Unknown"];

export default class CharacterBanner extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    character: PropTypes.shape({
      emblemBackgroundPath: PropTypes.string.isRequired,
      emblemPath: PropTypes.string.isRequired,
      classType: PropTypes.number.isRequired,
      raceType: PropTypes.number.isRequired,
      genderType: PropTypes.number.isRequired,
      light: PropTypes.number.isRequired,
      levelProgression: PropTypes.shape({
        level: PropTypes.number.isRequired
      })
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.state = { characterRemoved: false };
  }

  render() {
    let { character } = this.props;

    if (this.state.characterRemoved) {
      return null
    }

    return (
      <div>
        <div
          className={"mb-1 border border-glass rounded " + "character" + (this.props.onClick || this.props.authorizedUser ? " clickable" : "")}
          style={{
            backgroundImage: `url( 'https://www.bungie.net${character.emblemBackgroundPath}' )`,
            backgroundSize: 'cover'
          }}
          onClick={() => this.onClick()}
        >
          {this.props.hideEmblem ? (
            <div style={{ width: "50px" }} ></div>
          ) : (
              <img
                className="emblem"
                src={`https://www.bungie.net${character.emblemPath}`}
                alt={""}
              />
            )}
          <div className="info-left">
            <div className="class">{CLASS_TYPES[character.classType]}</div>
            <div className="race-gender">
              {RACE_TYPES[character.raceType] +
                " " +
                GENDER_TYPES[character.genderType]}
            </div>
          </div>
          <div className="info-right">
            <div className="light-level">{character.light}</div>
            <div className="level">Level {character.levelProgression.level}</div>
          </div>
        </div>
      </div>
    );
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.character);
    } else {
      this.onRemove()
    }
  }

  onRemove() {
    if (this.props.authorizedUser) {
      // this.setState({ characterRemoved: true })
      api
        .removeCharacter(this.props.confirmedSessionId, this.props.characterId)
        .then(function (response) {
          window.location.reload();
        });
    }
  }
}
