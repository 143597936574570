import React from "react";
import moment from "moment";


export default class FeedImage extends React.Component {

  render() {
    if (this.props.imageUrl) {

      return (
        <div >
          <a href={this.props.item.target_url}><img style={{ width: "100%", maxHeight: 200, objectFit: "cover" }}
            src={this.props.imageUrl}
            alt={this.props.item.title} /></a>

          {this.props.item.data && this.props.item.data.gaming_session_start_time && (
            <p className="text-center text-light mb-0">Starts: {moment(this.props.item.data.gaming_session_start_time).format("hh:mm A  MM/DD/YY")}</p>
          )}
        </div>
      );
    }
    else if (this.props.item.embed_url) {
      return <MessageBody text={this.props.item.embed_url} />;
    } else {
      return null;
    }
  }
}

