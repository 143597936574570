/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import ReactOnRails from "react-on-rails";

import CharacterBanner from "../components/CharacterBanner";
import CharacterImporter from "../components/CharacterImporter";
import ConversationPreviews from "../components/ConversationPreviews";
import SignupForm from '../components/SignupForm';
import GroupCreateForm from '../components/GroupCreateForm'
import GameTime from '../components/GameTime';
import ConversationPage from "../pages/ConversationPage";
import HelpPage from "../pages/HelpPage";
import GamingSessionsPage from "../pages/GamingSessionsPage";
import GroupsPage from "../pages/GroupsPage";
import GroupModsPage from "../pages/GroupModsPage";
import HqPage from "../pages/HqPage";
import SherpaHqPage from "../pages/SherpaHqPage";
import ApprovedGames from "../components/ApprovedGames";
import GroupDescription from "../components/GroupDescription";
import GroupModIntro from "../components/GroupModIntro";
import GroupTags from "../components/GroupTags";
import UserTags from "../components/UserTags";
import UserFeed from "../components/UserFeed";
import PlatformMemberships from "../components/PlatformMemberships";
import GamingSessionForm from "../components/GamingSessionForm";





ReactOnRails.register({
  CharacterBanner,
  CharacterImporter,
  ConversationPreviews,
  ConversationPage,
  HelpPage,
  GamingSessionsPage,
  GroupsPage,
  GroupModsPage,
  HqPage,
  SherpaHqPage,
  SignupForm,
  GroupCreateForm,
  GameTime,
  ApprovedGames,
  GroupDescription,
  GroupModIntro,
  GroupTags,
  UserTags,
  UserFeed,
  PlatformMemberships,
  GamingSessionForm,
});
