import React from "react";
import moment from "moment";


export default class Header extends React.Component {

  render() {
    return (
      <div className="card-header p-2">
        <div className="media">
          <a className="align-self-start mr-3" href={"/users/" + this.props.author_user_id} >
            <img className="avatar avatar-sm" src={
              !this.props.author_avatar_url ||
                this.props.author_avatar_url === "img/default-avatar.png"
                ? "https://www.the100.io/default-avatar.jpg"
                : this.props.author_avatar_url
            } />
          </a>
          <div className="media-body">
            <div className="pull-right small text-light">{moment(this.props.display_after).fromNow()}</div>

            <h6 className="mt-0 mb-0 p-0">{this.props.title}</h6>
            <p className="mt-0 mb-0 p-0 text-light" style={{ lineHeight: 1 }}>@{this.props.author_gamertag}</p>
          </div>
        </div>

      </div >
    )
  }
}

